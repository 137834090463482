"use client";

import PopUp from "../ui/popup";
import AdvancedModal, { AdvancedModalClassNames } from "./advanced-modal";
import useIsDesktop from "@/hooks/useIsDesktop";

export interface LakaSwitchMobileModalClassNames
  extends AdvancedModalClassNames {}
interface Props {
  body: any;
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  classNames?: LakaSwitchMobileModalClassNames;
  hiddenHeader?: boolean;
  minHeightContent?: string;
  maxHeightBody?: string;
  widthScreen?: number;
}

function LakaSwitchMobileModal({
  body,
  isOpen,
  onClose,
  title,
  classNames,
  minHeightContent,
  maxHeightBody,
  hiddenHeader = false,
  widthScreen,
}: Props) {
  const isDeskTop = useIsDesktop(widthScreen);

  if (!isDeskTop) {
    return (
      <PopUp
        body={body}
        isOpen={isOpen}
        onClose={onClose}
        minHeightContent={minHeightContent}
        maxHeightBody={maxHeightBody}
      />
    );
  }
  return (
    <AdvancedModal
      body={body}
      isOpen={isOpen}
      onClose={onClose}
      title={title || ""}
      hiddenHeader={hiddenHeader}
      classNames={classNames}
    />
  );
}

export default LakaSwitchMobileModal;
