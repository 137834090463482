import useItineraryQuestionFormV3Store from "./itinerary-question-form-v3-store";
import { toast } from "@/components/ui/use-toast";
import { ItineraryQueryApiClient } from "@/lib/apiClient/itinerary-query-api-client";
import { getClientId } from "@/lib/local/laka-local-storage";
import { trackClickGenerateIti } from "@/lib/metrics/ga/itinerary";
import { useRouter } from "next/navigation";
import { useState } from "react";

const useGenerateQuery = () => {
  const router = useRouter();
  const {
    totalDays,
    interests,
    selectedCountry,
    selectedItems,
    dateRange,
    selectedActivities,
  } = useItineraryQuestionFormV3Store();

  const [loading, setLoading] = useState(false);

  const generateQuery = async () => {
    trackClickGenerateIti({
      act_amount: selectedActivities.length,
      city: selectedItems.map((item) => item.name).join(", "),
      country: selectedCountry || selectedItems[0].countryName || "",
    });
    const destinations = selectedItems.map((item, index) => {
      return {
        index: index,
        country: item.countryName || "",
        destination: item.name,
        activities: selectedActivities
          .filter((act) => act.cityName === item.name)
          .map((act) => act.name)
          .join(", "),
      };
    });
    const responseData = await ItineraryQueryApiClient.query({
      destinations: destinations,
      days: totalDays,
      interests: interests.join(","),
      country: selectedCountry || selectedItems[0].countryName,
      startDay: dateRange.from,
      endDay: dateRange.to,
      clientId: getClientId(),
    });
    if (responseData.code !== 2000) {
      return toast({
        title: "Something went wrong.",
        description: responseData.message,
        variant: "destructive",
      });
    }
    return responseData;
  };

  const onGenerate = async () => {
    setLoading(true);
    await generateQuery().then((responseData) => {
      setLoading(false);
      router.push("/lich-trinh-du-lich/" + responseData.data + "/result");
    });
  };
  return { loading, onGenerate };
};

export default useGenerateQuery;
