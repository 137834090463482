import { Content, Dialog, Overlay } from "@radix-ui/react-dialog";
import React, { useEffect, useState } from "react";

interface PopUpProps {
  isOpen?: boolean;
  body?: React.ReactElement;
  onClose?: () => void;
  backdrop?: string;
  minHeightContent?: string;
  bgGradient?: boolean;
  maxHeightBody?: string;
  maxWidth?: string;
}

const PopUp: React.FC<PopUpProps> = ({
  isOpen,
  body,
  onClose,
  backdrop = "bg-backdrop",
  bgGradient = true,
  minHeightContent = "min-h-[55vh]",
  maxHeightBody = "max-h-[90dvh]",
  maxWidth = "md:max-w-3xl",
}) => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isOpen)
      setTimeout(() => {
        setIsClosing(true);
      }, 1000);
    else setIsClosing(false);
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="flex h-full items-center justify-center ">
      {isOpen && (
        <Dialog open={isOpen}>
          <Overlay
            className={`fixed inset-0 z-[100] h-dvh ${backdrop} outline-none duration-0`}
            onClick={isClosing ? onClose : undefined}
          />
          <Content
            className={`fixed inset-x-0 bottom-0 z-[100] flex  ${minHeightContent}
            animate-slide-up items-end justify-center
            rounded-md outline-none ${
              bgGradient && "bg-gradient-to-b from-white/0 to-white shadow-lg"
            }`}
          >
            <div
              className={`no-scrollbar size-full overflow-y-auto rounded-t-3xl ${maxWidth} ${maxHeightBody}`}
              id="scrollable-container"
            >
              {body}
            </div>
          </Content>
        </Dialog>
      )}
    </div>
  );
};

export default PopUp;
