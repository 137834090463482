"use client";

import { Icons } from "../icons";
import { sendEventGA } from "@/lib/google/google-analytics";
import Image from "next/image";
import Link from "next/link";
import { memo, useCallback } from "react";
import Slider from "react-slick";

interface IData {
  id: string;
  src: string;
  title: string;
  description: string;
}

const data: IData[] = [
  {
    id: "slide-1",
    src: "/images/home/image-right/image-right-2x.webp",
    title: "Nhận ngay Combo ưu đãi đến 40%",
    description: "khi mua hoạt động vui chơi trên lịch trình",
  },
  {
    id: "slide-2",
    src: "/images/home/image-left/image-left-2x.webp",
    title: "Lên lịch trình đúng ý",
    description: "theo sở thích của riêng bạn",
  },
  {
    id: "slide-3",
    src: "/images/home/image-center/image-center-2x.webp",
    title: "Tích hợp bản đồ",
    description: "gợi ý đường đi ngắn nhất",
  },
];

const SlideItem = memo(({ item }: { item: IData }) => (
  <div
    key={item.id}
    className="flex w-full flex-col items-center justify-center gap-2 px-3 md:px-1.5"
  >
    <div className="aspect-square w-full">
      <Image
        src={item.src}
        alt={item.title}
        className="size-full object-cover"
        width={500}
        height={500}
        loading="lazy"
      />
    </div>
    <div className="flex w-full flex-col gap-2 px-4 py-2">
      <h3 className="text-center text-base font-semibold text-black">
        {item.title}
      </h3>
      <p className="text-center text-base font-normal text-black">
        {item.description}
      </p>
    </div>
  </div>
));

SlideItem.displayName = "SlideItem";

const sliderSettings = {
  dots: false,
  centerMode: false,
  infinite: true,
  touchMove: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 1,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        centerMode: true,
        infinite: true,
        touchMove: true,
      },
    },
  ],
  customPaging: () => (
    <div className="flex w-full justify-center">
      <div className="dot size-2 rounded-full bg-primary-20"></div>
    </div>
  ),
};

const HomeSlide = () => {
  const handleClick = useCallback((eventName: string) => {
    sendEventGA(eventName);
  }, []);

  return (
    <div className="flex justify-center">
      <div className="home-slider flex w-full max-w-[1140px] flex-col gap-2 pb-10 pt-14 md:gap-8">
        <h2 className="text-center text-xl font-semibold leading-8 text-black md:text-[28px] md:leading-10">
          Laka lên kế hoạch cùng bạn
        </h2>
        <div
          className="no-scrollbar overflow-hidden"
          onClick={() => handleClick("hp_iti_banner2_click")}
        >
          <Slider {...sliderSettings}>
            {data.map((item) => (
              <SlideItem item={item} key={item.id} />
            ))}
          </Slider>
          <div className="mt-12 flex w-full justify-center md:mt-8">
            <Link
              href="/tao-lich-trinh"
              onClick={() => handleClick("hp_iti_banner1_cta_click")}
            >
              <button className="flex flex-row gap-2 text-nowrap rounded-full bg-[#7A67E4] px-6 py-3.5 text-center text-base font-medium text-white">
                <span>
                  <Icons.AI />
                </span>
                <span>Tạo lịch trình ngay</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HomeSlide);
