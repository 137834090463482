"use client";

import { Icons } from "../icons";
import HomeActivityPublic from "./home-activity-public";
import { sendEventGA } from "@/lib/google/google-analytics";
import Image from "next/image";
import Link from "next/link";
import { useMemo } from "react";

interface HomeCategory {
  id: number;
  src: string;
  title: string;
  redTitle?: string;
  description?: string;
  background: string;
  type: string;
  checkList: {
    bold: string;
    normal: string;
    red?: boolean;
  }[];
}

const homeCategory: HomeCategory[] = [
  {
    id: 1,
    src: "/images/home/image-section2/img-section2-2x.webp",
    title: "SIM / e-SIM 4G ",
    type: "CONNECTIVITY",
    redTitle: "chỉ từ 59.000đ",
    description: "Nhanh Chóng, Tiết Kiệm, Kết Nối Mọi Nơi",
    background: "#FBF9F6",
    checkList: [
      {
        bold: "Rẻ hơn đến 50%",
        normal: "khi mua tại sân bay",
      },
      {
        bold: "Data 4G",
        normal: "tốc độ cao, lướt mạng mượt mà",
      },
      {
        bold: "Hỗ trợ",
        normal: "24/7",
      },
    ],
  },
  {
    id: 2,
    src: "/images/home/image-section3/img-section3-2x.webp",
    type: "ATTRACTION",
    title: "Vé tham quan và Trải nghiệm",
    background: "#F8FAFC",
    checkList: [
      {
        bold: "Rẻ hơn đến 60%",
        normal: " so với mua tại cổng",
        red: true,
      },
      {
        bold: "Không cần thời gian ",
        normal: "xếp hàng, quét mã QR vào cổng ngay ",
      },
      {
        bold: "Miễn phí huỷ",
        normal: "",
      },
    ],
  },
];

const HomeEcomCategory = ({ ecomActivityCountry }) => {
  const handleClick = (itemId) => {
    if (itemId === 1) sendEventGA("hp_ecom_banner1_click");
    if (itemId === 2) sendEventGA("hp_ecom_banner2_click");
  };

  const handleCTAClick = (itemId) => {
    if (itemId === 1) sendEventGA("hp_ecom_banner1_cta_click");
    if (itemId === 2) sendEventGA("hp_ecom_banner2_cta_click");
  };

  return (
    <div className="mt-10 flex flex-col gap-6 px-4 pb-6">
      <div className="flex flex-col gap-3">
        {homeCategory.map((item, index) => {
          const isReverseOrder = index === 0;

          return (
            <div
              key={item.id}
              className="container flex flex-col gap-6 rounded-3xl px-4 py-[18px] transition-all duration-300 hover:scale-[101%] hover:shadow-lg md:items-center md:p-6"
              style={{ background: item.background }}
              onClick={() => handleClick(item.id)}
            >
              <div
                className={`flex w-full flex-col gap-6 md:flex-row ${
                  isReverseOrder ? "md:flex-row-reverse" : ""
                }`}
              >
                <Image
                  width={534}
                  height={344}
                  src={item.src}
                  alt="home-ecom-category-img"
                  className="w-full rounded-2xl object-cover md:w-1/2"
                  loading="lazy"
                />
                <div className="flex w-full flex-col justify-center md:w-1/2 md:pl-6">
                  <div className="text-xl font-semibold leading-8 text-secondary-100 md:text-[28px] md:leading-10">
                    {item.title}{" "}
                    <span className="text-[#FF3C62]">{item.redTitle}</span>
                  </div>
                  <div className="mt-0.5 text-lg font-semibold text-secondary-100 md:text-xl md:leading-8">
                    {item.description}
                  </div>
                  <ul className="my-4 flex flex-col gap-2 md:my-5 md:gap-3">
                    {item.checkList.map((li) => (
                      <li className="flex flex-row items-center gap-3 text-sm font-normal text-secondary-100 md:text-base">
                        <div className="size-6 min-w-6 md:size-8 md:min-w-8">
                          <Icons.CheckCircle className="size-6 md:size-8" />
                        </div>
                        <div>
                          <span
                            className={`${
                              li?.red ? "text-[#FF3C62] " : ""
                            } font-semibold`}
                          >
                            {li.bold}{" "}
                          </span>
                          {li.normal}
                        </div>
                      </li>
                    ))}
                  </ul>

                  <Link
                    href={`/hoat-dong-dich-vu?type=${item.type}`}
                    onClick={() => handleCTAClick(item.id)}
                  >
                    <button className="w-fit rounded-full bg-[#7A67E4] px-4 py-3 text-center text-sm font-medium text-white md:px-10 md:py-4 md:text-base">
                      Khám phá ngay
                    </button>
                  </Link>
                </div>
              </div>
              {item.id === 2 && (
                <HomeActivityPublic
                  ecoms={ecomActivityCountry}
                  href={`/hoat-dong-dich-vu?type=${item.type}`}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomeEcomCategory;
