"use client";

import useIsDesktop from "../../../../hooks/useIsDesktop";
import useItineraryQuestionFormV3Store from "../hooks/itinerary-question-form-v3-store";
import useSearchLocation from "../hooks/useSearchLocation";
import InputItem from "./InputItem";
import { Icons } from "@/components/icons";
import { I18nCountries } from "@/lib/i18n/i18n-countries";
import { useRouter, useSearchParams } from "next/navigation";
import { useQueryState } from "nuqs";
import React, { useEffect, useRef, useState } from "react";

interface LocationInputProps {
  checkValid?: boolean;
  className?: string;
}

const LocationInput = ({ checkValid, className }: LocationInputProps) => {
  const {
    selectedItems,
    selectedCountry,
    selectedActivities,
    setSelectedActivities,
    setSelectedCountry,
    setSelectedItems,
  } = useItineraryQuestionFormV3Store();

  const router = useRouter();
  const [active, setActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchKeyword, setSearchKeyword] = useQueryState("searchKeyword");
  const [inputValue, setInputValue] = useState("");
  const isMaxSelectedItems = selectedItems.length === 5;
  const isDesktop = useIsDesktop();

  const searchParams = useSearchParams();
  const searchKeywordUrl = searchParams?.get("keyword");
  const selectedCountryUrl = searchParams?.get("selectedCountry");
  const { getFilteredCities } = useSearchLocation();

  useEffect(() => {
    if (searchKeywordUrl) {
      if (selectedCountryUrl === "true") {
        setTimeout(() => {
          setSelectedCountry(searchKeywordUrl);
          setInputValue("");
          return;
        }, 100);
      } else {
        const search = getFilteredCities(5, searchKeywordUrl, "");
        if (search[0]) {
          setTimeout(() => {
            setSelectedItems([]);
            setSelectedCountry("");
            addNewDestination(search[0]);
          }, 100);
        } else {
          setInputValue(searchKeywordUrl);
        }
      }
    }
  }, [searchKeywordUrl]);

  useEffect(() => {
    if (!selectedItems.length) setActive(true);
    if (searchKeyword && !selectedItems.length) {
      const result = getFilteredCities([], searchKeyword, "");
      if (result.length)
        setSelectedItems([{ ...result[0], countryName: result[0].country }]);
    }
  }, [router, searchKeyword, selectedItems]);

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        focusInput();
      }, 100);
    }
  }, [active]);

  const items = React.useMemo(
    () =>
      getFilteredCities(
        5,
        inputValue,
        selectedCountry || selectedItems[0]?.countryName || ""
      ),
    [inputValue, selectedCountry]
  );

  function addNewDestination(item) {
    if (item.type == "country") {
      console.log(item, "item");

      setSelectedCountry(item.country);
      setInputValue("");
      return;
    }
    if (
      selectedItems.find((selectedItem) => selectedItem.name === item.name) ===
      undefined
    ) {
      setSelectedItems([
        ...selectedItems,
        { ...item, countryName: item.country },
      ]);
      setInputValue("");
    }
  }

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const content = (
    <>
      {inputValue && !!items.length && !isMaxSelectedItems && (
        <div className="w-screen max-w-[343px] rounded-3xl border border-gray-100 bg-white shadow md:max-w-[588px]">
          <ul className={`justify-stretch`}>
            {items.map((item, index) =>
              selectedItems.find(
                (selectedItem) => item.name == selectedItem?.name
              ) ? (
                <li></li>
              ) : (
                <div
                  key={`${item.name}${index}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    addNewDestination(item);
                    focusInput();
                  }}
                  className={`h-16 w-full cursor-pointer p-4 hover:bg-[#EEF0F5] ${
                    index === items.length - 1
                      ? ""
                      : "border-b border-[#D8DAE2]"
                  } inline-flex items-center justify-start gap-2`}
                >
                  <div className="flex items-center justify-start gap-2 rounded-2xl bg-gray-200 p-1">
                    <Icons.itineraryInputMap className="relative size-5 md:size-6" />
                  </div>
                  <div className="text-base font-medium leading-tight text-indigo-950">
                    <span>{item.name}</span>
                    <span>
                      {item.type != "country" &&
                        item.name !== item.country &&
                        `, ${I18nCountries.userLanguageName(item.country)}`}
                    </span>
                  </div>
                </div>
              )
            )}
          </ul>
        </div>
      )}
    </>
  );

  return (
    <div className="flex w-full flex-col gap-2">
      <InputItem
        content={content}
        open={active}
        setOpen={setActive}
        isClear={
          (selectedItems.length > 0 || !!selectedCountry || !!inputValue) &&
          active
        }
        onClear={() => {
          setSelectedItems([]);
          setSelectedActivities([]);
          setSelectedCountry("");
          setSearchKeyword(null);
          setInputValue("");
          focusInput();
        }}
        className={className}
        helperText="Bạn chưa chọn điểm đến. Hãy nhập thông tin để Laka tiếp tục giúp bạn
        nhé!"
        isError={
          checkValid &&
          (!selectedItems.length && selectedCountry
            ? false
            : !selectedItems.length)
        }
      >
        <>
          <Icons.itineraryInputMap
            className="relative "
            stroke={active ? "#517CFF" : "#111B42"}
            width={isDesktop ? 24 : 20}
            height={isDesktop ? 24 : 20}
          />
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start">
            <div className="text-sm font-semibold leading-normal text-indigo-950 md:text-base">
              Bạn muốn tạo lịch trình đi đâu?
            </div>
            <div className="line-clamp-1 inline-flex w-full items-center justify-start gap-1 self-stretch">
              {selectedCountry && (
                <>
                  <div
                    className={`px-1 ${
                      active ? "bg-gray-300" : ""
                    } whitespace-nowrap rounded text-center text-base font-medium leading-normal text-indigo-950`}
                  >
                    {selectedCountry}
                  </div>
                  {!!selectedItems.length && !active && <span> • </span>}
                </>
              )}

              {selectedItems?.map((item, index) => (
                <>
                  <div
                    className={`px-1 ${
                      active ? "bg-gray-300" : ""
                    } whitespace-nowrap rounded text-center text-base font-medium leading-normal text-indigo-950`}
                  >
                    {item.name}
                  </div>
                  {index !== selectedItems.length - 1 && !active && (
                    <span> • </span>
                  )}
                </>
              ))}

              <input
                ref={inputRef}
                onKeyDown={(event) => {
                  if (
                    event.key === "Delete" ||
                    (event.key === "Backspace" && !inputValue)
                  ) {
                    if (selectedItems.length) {
                      const newSelectedItems = [...selectedItems];
                      const itemDeleted = newSelectedItems.pop();
                      if (itemDeleted)
                        setSelectedActivities([
                          ...selectedActivities.filter(
                            (act) => act.cityName !== itemDeleted.name
                          ),
                        ]);
                      setSelectedItems(newSelectedItems);
                    } else setSelectedCountry("");
                  }
                }}
                value={inputValue}
                onChange={(e) => {
                  const value = e.target.value;
                  setInputValue(value);
                  if (value.trim()) {
                    setTimeout(() => {
                      focusInput();
                    }, 50);
                  }
                }}
                placeholder={
                  selectedItems.length > 0 || selectedCountry
                    ? ""
                    : "VD: Bangkok, Singapore, Kuala Lumpur,..."
                }
                className="w-full border-none p-0 text-base font-medium caret-[#517CFF] outline-0 placeholder:text-[#D8DAE2] focus:outline-none focus:ring-0"
              />
            </div>
          </div>
        </>
      </InputItem>
    </div>
  );
};

export default LocationInput;
