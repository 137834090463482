import { TravelActivityItem } from "@/actions/travel-activity";
import { TravelCity, TravelCityPhoto } from "@prisma/client";
import { DateRange } from "react-day-picker";
import { create } from "zustand";

export interface CityData extends Partial<TravelCity> {
  name: string;
  countryName?: string;
  photos?: TravelCityPhoto[];
}
interface ItineraryQuestionFormV3Store {
  selectedCountry: string;
  selectedItems: CityData[];
  totalDays: number;
  interests: string[];
  dateRange: DateRange;
  selectedActivities: TravelActivityItem[];
  isExactDay?: boolean;

  setSelectedActivities: (selectedActivities: TravelActivityItem[]) => void;
  reset: () => void;
  setDateRange: (date: DateRange) => void;
  setInterests: (interests: string[]) => void;
  setTotalDays: (total: number | ((prevValue: number) => number)) => void;
  setSelectedCountry: (country: string) => void;
  setSelectedItems: (items: CityData[]) => void;
  init: (step: Partial<ItineraryQuestionFormV3Store>) => void;
  setIsExactDay: (isExactDay: boolean) => void;

}
const initStore = {
  selectedCountry: "",
  selectedItems: [],
  selectedActivities: [],
  interests: [],
  totalDays: 0,
  isExactDay: false,
  dateRange: { from: undefined, to: undefined },
};
const useItineraryQuestionFormV3Store = create<ItineraryQuestionFormV3Store>(
  (set) => ({
    ...initStore,
    reset: () => {
      set(initStore);
    },
    setTotalDays: (total) =>
      set((prevState) => ({
        ...prevState,
        totalDays:
          typeof total === "function"
            ? (total as (prevData: number) => number)(prevState.totalDays)
            : total,
      })),

    setDateRange: (dateRange) => set({ dateRange }),
    setSelectedActivities: (selectedActivities) => set({ selectedActivities }),
    setInterests: (interests) => set({ interests }),
    setSelectedCountry: (country) => set({ selectedCountry: country }),
    setSelectedItems: (items) => set({ selectedItems: items }),
    init: (data) => set({ ...data }),
    setIsExactDay: (isExactDay) => set({ isExactDay }),
  })
);

export default useItineraryQuestionFormV3Store;
