"use client";

import { Icons } from "../icons";
import { Content, Dialog, Overlay } from "@radix-ui/react-dialog";
import React, { useEffect, useState } from "react";

export interface AdvancedModalClassNames {
  container?: string;
  body?: string;
  content?: string;
  overlay?: string;
}
interface AdvancedModalProps {
  isOpen?: boolean;
  body?: React.ReactElement;
  onClose?: () => void;
  title?: string;
  hiddenHeader?: boolean;
  classNames?: AdvancedModalClassNames;
}

const AdvancedModal: React.FC<AdvancedModalProps> = ({
  isOpen,
  body,
  title = "",
  onClose,
  hiddenHeader = false,
  classNames = {},
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const {
    container: containerClassName = "",
    body: bodyClassName = "",
    content: contentClassName = "",
    overlay: overlayClassName = "",
  } = classNames;
  useEffect(() => {
    if (isOpen)
      setTimeout(() => {
        setIsClosing(true);
      }, 1000);
    else setIsClosing(false);
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="flex items-center justify-center">
      {isOpen && (
        <Dialog open={isOpen}>
          <Overlay
            className={
              `fixed inset-0 z-[90] h-svh bg-backdrop outline-none duration-0 ` +
              overlayClassName
            }
            onClick={isClosing ? onClose : undefined}
          />
          <Content
            className={
              `fixed inset-x-0 bottom-0 z-[100] flex h-full animate-slide-up items-end justify-center rounded-md pt-10 outline-none pb-5 ` +
              containerClassName
            }
          >
            <div
              className={
                `relative flex size-full flex-col items-center max-w-6xl ` +
                contentClassName
              }
            >
              {!hiddenHeader && (
                <div
                  className={`fixed flex h-[60px] w-full max-w-6xl items-center justify-between rounded-t-3xl bg-white px-4 py-3 `}
                >
                  <div className="cursor-pointer" onClick={onClose}>
                    <Icons.arrowLeft className="h-9 w-6" />
                  </div>
                  <div className="flex-1 text-center text-base font-semibold text-secondary-100">
                    {title}
                  </div>
                </div>
              )}
              <div
                id="scrollable-container"
                className={
                  `no-scrollbar ${
                    hiddenHeader ? "mt-0" : "mt-[60px]"
                  } size-full overflow-y-auto rounded-3xl bg-white pb-10 px-10 ` +
                  bodyClassName
                }
              >
                {body}
              </div>
            </div>
          </Content>
        </Dialog>
      )}
    </div>
  );
};

export default AdvancedModal;
