"use client";

import { getCurrentShoppingSession } from "../../../../actions/ecom-cart-v2";
import CartShoppingIcon from "../../../../components/cart-shopping-icon";
import { getClientId } from "../../../../lib/local/laka-local-storage";
import HomeMenuHeaderMobile from "../components/home-menu-header-mobile";
import { Icons } from "@/components/icons";
import { Logo } from "@/components/logo/logo";
import {
  trackClickEcomHeader,
  trackClickItiCreateHeader,
  trackClickMyAccount,
  trackClickSignIn,
} from "@/lib/metrics/ga/homepage";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { Fragment, memo, useEffect, useState } from "react";
import useLoginModalStore from "../../../../hooks/auth/use-login-modal-store";
import { useQueryState } from "nuqs";

export const headerItem = [
  {
    value: "/tao-lich-trinh",
    text: "✏️ Tạo lịch trình",
    onClick: trackClickItiCreateHeader,
  },
  {
    value: "/hoat-dong-dich-vu",
    text: "🏖 Hoạt động & vui chơi",
    onClick: trackClickEcomHeader,
  },
];

const Header = ({ user, showNavigation = true }) => {
  const pathName = usePathname();
  const router = useRouter();
  const [totalItemsAdded, setTotalItemsAdded] = useState(0);
  const loginModal = useLoginModalStore();
  const [callBackUrl, setCallBackUrl] = useQueryState("callbackUrl");

  const handleLogin = () => {
    if (!user) {
      trackClickSignIn();
      loginModal.onOpen(window.location.href);
    } else {
      router.push("/personal");
      trackClickMyAccount();
      setOpenMenuMobile(false);
    }
  };

  useEffect(() => {
    if(!!callBackUrl){
      loginModal.onOpen(callBackUrl)
    }
  }, [callBackUrl]);

  const [openMenuMobile, setOpenMenuMobile] = useState(false);

  useEffect(() => {
    getCurrentShoppingSession(getClientId()).then((res) => {
      if (res?._count.items > 0) {
        setTotalItemsAdded(res._count.items);
      }
    });
  }, []);

  return (
    <>
      <div className="flex flex-row items-center justify-between p-4 md:px-10">
        <div className="flex flex-1 justify-start">
          <Link href={"/"}>
            <Logo width={[62, 29]} height={22} />
          </Link>
        </div>
        {showNavigation && (
          <div className="hidden flex-1 flex-row flex-nowrap items-center justify-center md:flex">
            {headerItem.map((item, index) => (
              <Fragment key={item.value}>
                <Link
                  onClick={item.onClick}
                  href={item.value}
                  className={`mx-2 cursor-pointer text-nowrap px-4 py-2 ${
                    pathName === item.value && "rounded-full bg-support-yellow"
                  }`}
                >
                  <div
                    className="m-0 whitespace-nowrap text-nowrap p-0 text-base text-secondary-100 "
                    style={{ fontWeight: 600 }}
                  >
                    {item.text}
                  </div>
                </Link>
                {index !== headerItem.length - 1 && (
                  <span className="h-6 border-l-2" />
                )}
              </Fragment>
            ))}
          </div>
        )}

        <div className="flex flex-1 flex-row items-center justify-end gap-4 md:gap-6">
          <CartShoppingIcon />
          <button className="md:hidden" onClick={() => setOpenMenuMobile(true)}>
            <Icons.menu className="size-6 cursor-pointer" />
          </button>

          <div onClick={handleLogin} className="hidden md:block ">
            <button className="box-border h-14 text-nowrap rounded-full border border-stroke-secondaryLight bg-white px-6 py-4 text-base font-semibold text-indigo-950 ">
              {user ? user?.name || user?.email : "Đăng nhập"}
            </button>{" "}
          </div>
        </div>
      </div>
      <HomeMenuHeaderMobile
        isOpen={openMenuMobile}
        onClose={() => setOpenMenuMobile(false)}
        user={user}
        handleLogin={handleLogin}
      />
    </>
  );
};

export default memo(Header);
