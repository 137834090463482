export const formatNumber = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "VND",
});
export const formatNumberVND = (number) => {
  const result = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "VND",
  })
    .format(number)
    .replace("₫","₫");
  return result;
};
