import { I18nCountries } from "../i18n/i18n-countries";
import Cities from "./compact-world-cities.json";
import CountriesData from "@/lib/data/countries-data";

const priorityCountries = [
  "Thailand",
  "Malaysia",
  "Singapore",
  "Phillipines",
  "Bali",
  "South Korea",
  "Japan",
  "Taiwan",
];

const ignoreCity = [
  { name: "Ubud", country: "Indonesia" },
  { name: "Tabanan", country: "Indonesia" },
  { name: "Singaraja", country: "Indonesia" },
  { name: "Negara", country: "Indonesia" },
  { name: "Kuta", country: "Indonesia" },
  { name: "Klungkung", country: "Indonesia" },
  { name: "Denpasar", country: "Indonesia" },
  { name: "Banjar", country: "Indonesia" },
  { name: "Banjar", country: "Indonesia" },
  { name: "Seririt", country: "Indonesia" },
];

const priorityCity = [
  "Bangkok",
  "Phuket",
  "Chiang Mai",
  "Chiang Rai",
  "Pattaya",
  "Krabi",
  "Hua Hin",
  "Ayutthaya",
  "Koh Samui",
  "Kanchanaburi",
  "Kuala Lumpur",
  "Penang",
  "Langkawi",
  "Malacca",
  "Ipoh",
  "Cameron Highlands",
  "Kuching",
  "Kota Kinabalu",
  "Johor Bahru",
  "George Town",
  "Manila",
  "Cebu City",
  "Boracay",
  "Palawan",
  "Bohol",
  "Davao City",
  "Angeles City",
  "Baguio",
  "Vigan",
  "Iloilo City",
  "Ubud",
  "Kuta",
  "Seminyak",
  "Canggu",
  "Uluwatu",
  "Nusa Dua",
  "Sanur",
  "Lovina",
  "Amed",
  "Sidemen",
  "Seoul",
  "Busan",
  "Incheon",
  "Jeju",
  "Daegu",
  "Gwangju",
  "Ulsan",
  "Daejeon",
  "Suwon",
  "Andong",
  "Tokyo",
  "Kyoto",
  "Osaka",
  "Hiroshima",
  "Nara",
  "Fukuoka",
  "Sapporo",
  "Nagoya",
  "Yokohama",
  "Kamakura",
  "Taipei",
  "Kaohsiung",
  "Tainan",
  "Taichung",
  "Hualien",
  "Keelung",
  "Taitung",
  "Chiayi",
  "Pingtung",
  "Yilan",
  "Hanoi",
  "Ho Chi Minh City",
];

const generateDestinations = () => {
  const cities: {
    name: string;
    country: string;
    type: string;
    countryCode: string;
    latitude: string;
    longitude: string;
  }[] = [];
  Cities.forEach((country) => {
    const countryCities = country.cities;
    countryCities.forEach((item) => {
      cities.push({
        name: item.n,
        country: country.c,
        type: "city",
        countryCode: country.cc,
        latitude: item.la,
        longitude: item.lg,
      });
    });
  });

  CountriesData()
    .getAll()
    .forEach((item) => {
      if (item.label === "Singapore") return;
      cities.unshift({
        name: I18nCountries.userLanguageName(item.label),
        country: item.label,
        type: "country",
        countryCode: item.value,
        latitude: item.latlng[0],
        longitude: item.latlng[1],
      });
    });

  return cities.sort((d1, d2) => {
    if (
      priorityCountries.includes(d1.country) !==
      priorityCountries.includes(d2.country)
    ) {
      return priorityCountries.includes(d1.country) ? -1 : 1;
    } else if (d1.type !== d2.type) {
      return d1.type == "country" ? -1 : 1;
    } else if (d1.country == d2.country) {
      return cityPriority(d2.name) - cityPriority(d1.name);
    } else {
      return countryPriority(d2.country) - countryPriority(d1.country);
    }
  });
};

const destinations = generateDestinations();
const cities = destinations.filter((item) => item.type === "city");

function cityPriority(cityName: string): number {
  return priorityCity.includes(cityName)
    ? Number.MAX_SAFE_INTEGER - priorityCity.indexOf(cityName)
    : 0;
}

function countryPriority(country: string): number {
  return priorityCountries.includes(country)
    ? Number.MAX_SAFE_INTEGER - priorityCountries.indexOf(country)
    : 0;
}

function countryByCity(city: string): string {
  return cities.find((item) => item.name === city)?.country || "";
}
function cityByCountry(country: string) {
  return cities.filter((item) => item.country === country);
}
function cityByCountryCode(country: string | string[]) {
  // Kiểm tra kiểu của country
  if (Array.isArray(country)) {
    // country là một mảng chuỗi
    return cities.filter((item) => country.includes(item.countryCode));
  } else {
    // country là một chuỗi đơn
    return cities.filter((item) => item.countryCode === country);
  }
}

export const MasterData = {
  destinations: destinations,
  countryByCity,
  cityByCountry,
  cityByCountryCode,
  priorityCity,
  ignoreCity,
  cities: cities,
};
