"use client";

import useItineraryQuestionFormV3Store from "../hooks/itinerary-question-form-v3-store";
import ErrorMessage from "./ErrorMessage";
import { interestLine1Options } from "@/lib/constants";

interface InterestInputProps {
  checkValid?: boolean;
}

const InterestInput = ({ checkValid = false }) => {
  const { interests, setInterests } = useItineraryQuestionFormV3Store();
  function toggleInterest(interest) {
    if (interests.findIndex((item) => item == interest) > -1) {
      setInterests(interests.filter((item) => item != interest));
    } else {
      setInterests([...interests, interest]);
    }
  }

  const renderItemInterest = (item) => (
    <div
      onClick={() => toggleInterest(item.value)}
      className={`border px-3 py-2.5 ${
        interests.includes(item.value)
          ? " border-[#FFD451] bg-[#FFD451]"
          : "border-gray-300 bg-white"
      } flex cursor-pointer items-center justify-center gap-1 rounded-full`}
    >
      <div className={`text-nowrap text-sm font-semibold leading-tight`}>
        {item.text}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="text-base font-semibold leading-normal text-indigo-950">
        Sở thích du lịch của bạn là gì?
      </div>
      <div className="flex max-w-3xl flex-col gap-4">
        <div className="flex size-full flex-wrap gap-2">
          {interestLine1Options.map(renderItemInterest)}
        </div>
      </div>
      {checkValid && !interests.length && (
        <ErrorMessage text="Hãy chọn sở thích du lịch để Laka tiếp tục giúp bạn nhé!" />
      )}
    </div>
  );
};

export default InterestInput;
