"use client"

import React from 'react';
import { Logo } from '../../../../components/logo/logo';

const TitleVerificationStep = React.memo(({ props, setEmail, email }: any) => {
  return (
    <div className="flex w-full flex-col justify-center gap-1">
      <div>
        <Logo width={[62, 29]} height={22} />
      </div>
      <div className="text-center text-xl font-semibold text-secondary-100">
        Xác minh thông tin
      </div>
      <div className="text-center text-sm font-normal text-[#61677C]">
        <span>Hãy nhập mã xác minh được gửi đến</span>
        <div className="flex items-center justify-center gap-1">
          <span className="font-semibold text-secondary-100">{email}</span>
          <span
            className="cursor-pointer text-blue-1 underline"
            onClick={() => {
              props.setShowVerificationStep(false);
              setEmail("");
            }}
          >
            Thay đổi
          </span>
        </div>
      </div>
    </div>
  );
});

// Thêm tên hiển thị cho component
TitleVerificationStep.displayName = "TitleVerificationStep";

const TitlePopUp = () => {
  return (
    <div className="flex w-full flex-col justify-center gap-1">
      <div>
        <Logo width={[62, 29]} height={22} />
      </div>
      <div className="text-center text-xl font-semibold text-secondary-100">
        Đăng nhập/ đăng ký
      </div>
      <div className="text-center text-sm font-normal text-[#61677C]">
        Để lưu lại thông tin và khám phá ở bất cứ đâu trên Laka.
      </div>
    </div>
  );
};

export { TitleVerificationStep, TitlePopUp };