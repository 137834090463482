"use client";

import { Icons } from "../icons";
import InterestInput from "@/app/(ai-planning)/tao-lich-trinh/components/InterestInput";
import LocationInput from "@/app/(ai-planning)/tao-lich-trinh/components/LocationInput";
import TimeInput from "@/app/(ai-planning)/tao-lich-trinh/components/TimeInput";
import useItineraryQuestionFormV3Store from "@/app/(ai-planning)/tao-lich-trinh/hooks/itinerary-question-form-v3-store";
import useGenerateQuery from "@/app/(ai-planning)/tao-lich-trinh/hooks/useGenerateQuery";
import { sendEventGA } from "@/lib/google/google-analytics";
import { useRouter } from "next/navigation";
import { useState } from "react";

interface HomeInputItineraryProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

const HomeInputItinerary = ({
  expanded,
  setExpanded,
}: HomeInputItineraryProps) => {
  const router = useRouter();
  const { selectedItems, selectedCountry, dateRange, totalDays, interests } =
    useItineraryQuestionFormV3Store();
  const { onGenerate, loading } = useGenerateQuery();
  const [isSave, setIsSave] = useState(false);

  const handleSave = () => {
    if (!expanded) {
      sendEventGA("hp_iti_create_image_click");
      return setExpanded(true);
    }
    setIsSave(true);
    if (totalDays === 0 || interests.length === 0) {
      return;
    }
    if (loading) return;

    // Track event GA
    sendEventGA("hp_iti_cta_click", {
      country: selectedCountry || selectedItems[0]?.countryName || "",
      city: selectedItems.map((item) => item.name).join(", "),
      duration: totalDays,
      interest: interests.join(", "),
      interest_amount: interests.length,
      start_date: dateRange.from?.toDateString() || "",
      end_date: dateRange.to?.toDateString() || "",
    });

    const hasSelectedCountryWithoutItems =
      !selectedItems.length && selectedCountry;

    if (hasSelectedCountryWithoutItems) {
      const params = new URLSearchParams({
        keyword: selectedCountry,
        selectedCountry: "true",
        totalDays: totalDays.toString(),
        start_date: dateRange.from?.toDateString() || "",
        end_date: dateRange.to?.toDateString() || "",
        interests: interests.join(","),
      }).toString();

      router.push(`/tao-lich-trinh?${params}`);
    } else {
      onGenerate();
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex w-full max-w-6xl flex-1 flex-col items-center justify-between gap-6 pt-0 md:justify-start md:p-0">
        <div
          className={`inline-flex w-full flex-col items-center justify-start ${
            expanded && " gap-6"
          }`}
          onClick={(e) => {
            if (!expanded) {
              sendEventGA("hp_iti_create_searchbox_click");
              e.preventDefault();
              setExpanded(true);
            }
          }}
        >
          <LocationInput
            checkValid={isSave}
            className={!expanded ? "!border-2 !border-blue-500" : undefined}
          />
          <div
            className={`flex flex-col gap-6 transition-all duration-700 ease-in-out ${
              expanded
                ? "h-auto max-h-screen opacity-100"
                : "h-0 max-h-0 opacity-0"
            } `}
          >
            {expanded && (
              <>
                <TimeInput checkValid={isSave} scrollCenter={false} />
                <InterestInput checkValid={isSave} />
              </>
            )}
          </div>
        </div>
        <div className="md:bg-transparentF sticky bottom-0 inline-flex w-full items-center justify-center  gap-4 md:relative md:border-t-0">
          <button
            onClick={handleSave}
            className={`flex w-full cursor-pointer items-center justify-center gap-2 rounded-full bg-[#7A67E4] text-white  md:w-auto`}
          >
            <div className="flex flex-row gap-2 py-3.5 text-center text-base font-semibold leading-normal md:px-10">
              <span className="size-6">
                <Icons.AI className="size-6" />
              </span>
              <span>Tạo lịch trình ngay !</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeInputItinerary;
