"use client";

import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { vi } from "date-fns/locale";
import { ChevronLeft, ChevronRight } from "lucide-react";
import * as React from "react";
import { DayPicker } from "react-day-picker";

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  disabledBefore?: boolean;
  dayClassName?: string;
  disableToday?: boolean;
  isDayDisabled?: (date: Date) => boolean;
};
const today = new Date();
function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  disabledBefore = true,
  dayClassName,
  components,
  disableToday,
  isDayDisabled,
  ...props
}: CalendarProps) {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  return (
    <DayPicker
      locale={vi}
      disabled={isDayDisabled}
      showOutsideDays={showOutsideDays}
      className={cn("flex justify-center p-3", className)}
      classNames={{
        months:
          "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 items-start justify-center",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-indigo-950 text-base font-semibold",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "size-10 bg-transparent p-0"
        ),
        nav_button_previous: "absolute left-1 border-none",
        nav_button_next: "absolute right-1 border-none",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",

        head_cell: "size-10 text-indigo-950 text-base font-medium",
        row: "flex w-full first:justify-end",
        cell: "size-10 text-center text-base p-0 relative [&:has([aria-selected])]:bg-[#EEF0F5] focus-within:relative focus-within:z-0 first:[&:has([aria-selected])]:rounded-l-full [&:has(.selected-start)]:rounded-l-full last:[&:has([aria-selected])]:rounded-r-full [&:has(.selected-end)]:rounded-r-full",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "size-10 h-10 p-0 font-normal disabled:bg-white aria-selected:opacity-100",
          dayClassName
        ),
        day_selected:
          "inline-flex items-center justify-center rounded-full text-sm disabled:pointer-events-none white h-10 w-10 p-0 font-normal bg-[#111B42] text-white ",
        // day_today: "bg-accent text-accent-foreground",
        day_outside: "text-muted-foreground opacity-50",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_end: "selected-end",
        day_range_start: "selected-start",
        day_range_middle:
          "aria-selected:bg-[#EEF0F5] aria-selected:text-[#4C485E] text-black",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeft className="size-6" />,
        IconRight: ({ ...props }) => (
          <ChevronRight {...props} className="size-6" />
        ),
        ...components,
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
