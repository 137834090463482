import { Icons } from "@/components/icons";
import { PackageHighlightOptions } from "../enums/ecom-enum";

export const PACKAGE_HIGHLIGHT_OPTIONS = [
  {
    icon: Icons.EventAvailable,
    title: "Miễn phí huỷ",
    value: PackageHighlightOptions.FreeCancellation,
    defaultMessage: "Hoàn tiền 100% khi hủy trước 24h sử dụng dịch vụ",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.doorToDoorTransfer,
    title: "Đưa đón tận nơi",
    value: PackageHighlightOptions.DoorToDoorTransfer,
    defaultMessage: "Miễn phí đưa đón từ khách sạn trung tâm",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.mustDo,
    title: "Must-do",
    value: PackageHighlightOptions.MustDo,
    defaultMessage: "Top hoạt động được yêu thích nhất tại Bangkok",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.familyFriendly,
    title: "Thích hợp gia đình",
    value: PackageHighlightOptions.FamilyFriendly,
    defaultMessage: "Trẻ em đặc biệt yêu thích hoạt động này",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.duration,
    title: "Thời lượng",
    value: PackageHighlightOptions.Duration,
  },
  {
    icon: Icons.premiumExperience,
    title: "Trải nghiệm cao cấp",
    value: PackageHighlightOptions.PremiumExperience,
    defaultMessage: "Lưu trú tại khách sạn 5 sao",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.departureGuarantee,
    title: "Cam kết khởi hành",
    value: PackageHighlightOptions.DepartureGuarantee,
    defaultMessage: "Khởi hành hàng ngày dù chỉ với 1 khách",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.cheaperThanGatePrice,
    title: "Rẻ hơn mua tại cổng",
    value: PackageHighlightOptions.CheaperThanGatePrice,
    defaultMessage: "Rẻ hơn 40% so với mua tại quầy",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.noQueue,
    title: "Không cần xếp hàng",
    value: PackageHighlightOptions.NoQueue,
    defaultMessage: "Quét mã QR vào cổng trực tiếp",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.fastDelivery,
    title: "Giao hàng nhanh",
    value: PackageHighlightOptions.FastDelivery,
    defaultMessage: "",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.useImmediately,
    title: "Sử dụng ngay",
    value: PackageHighlightOptions.UseImmediately,
    defaultMessage: "Nhận mã QR sử dụng ngay",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.finalPrice,
    title: "Giá cuối cùng",
    value: PackageHighlightOptions.FinalPrice,
    defaultMessage: "Giá đã bao gồm phí vận chuyển 2 chiều",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.freeWaiting,
    title: "Miễn phí chờ",
    value: PackageHighlightOptions.FreeWaiting,
    defaultMessage: "Miễn phí chờ 2 tiếng sau khi hạ cánh",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.support247,
    title: "Hỗ trợ 24/7",
    value: PackageHighlightOptions.Support247,
    defaultMessage: "Liên hệ tổng đài bất kỳ khi nào cần trợ giúp",
    message: (metaData) => `${metaData}`,
  },
  {
    icon: Icons.SafeMoney,
    title: "Tiết kiệm nhất",
    value: PackageHighlightOptions.MostEconomical,
    defaultMessage: "Rẻ hơn tới 30% so với các nhà cung cấp khác",
    message: (metaData) => `${metaData}`,
  },
];
