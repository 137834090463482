"use client";

import { useState, useEffect } from "react";

const useIsDesktop = (defaultWidth = 768) => {
  const [isDesktop, setIsDesktop] = useState(
    typeof window !== "undefined" ? window.innerWidth >= defaultWidth : false
  );

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    const handleResize = () => {
      const currentlyDesktop = window.innerWidth >= defaultWidth;
      if (isDesktop !== currentlyDesktop) {
        setIsDesktop(currentlyDesktop);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop, defaultWidth]);

  return isDesktop;
};

export default useIsDesktop;
