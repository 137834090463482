"use client";

import { Icons } from "@/components/icons";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { signIn } from "next-auth/react";
import React, { useState } from "react";
import { z } from "zod";
import { Logo } from "../../../../components/logo/logo";
import useLoginModalStore from "../../../../hooks/auth/use-login-modal-store";
import { TitlePopUp, TitleVerificationStep } from "./custom-title";
import { VerificationStep } from "./verification-step";

export const LoginSchema = z.object({
  email: z.string().min(1).email(),
  password: z.string().min(6).optional(),
});

interface FormData {
  email: string;
  password?: string;
}
interface SignInContentProp {
  handleSetActive: () => void;
  callBackUrl: string;
  showVerificationStep: boolean;
  setShowVerificationStep: (value) => void;
}

const SignInWithEmail = (props: SignInContentProp) => {
  const loginModal = useLoginModalStore();
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setErrorMessage("");
    setEmail(e.target.value);
  };
  const handleSignin = async () => {
    if (email.trim() === "") {
      setErrorMessage("Vui lòng nhập email");
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setErrorMessage("Email không hợp lệ");
      return;
    }
    setLoading(true);
    const res = await signIn("email", {
      email: email,
      redirect: false,
    });
    console.log(res, "TSres");

    if (res?.error) {
      if (res?.url) {
        window.location.replace(res.url);
      }
      setLoading(false);
    } else {
      props.setShowVerificationStep(true);
      setLoading(false);
    }
  };

  const handleCloseModel = () => {
    props.handleSetActive();
    props.setShowVerificationStep(false);
    loginModal.onClose();
  };

  return (
    <div>
      <div className="flex w-full flex-col justify-center gap-4 pt-4">
        <div className="flex cursor-pointer justify-start md:justify-end">
          <Icons.close className="size-6" onClick={handleCloseModel} />
        </div>
        <div className="mb-2 text-center text-xl font-semibold text-secondary-100">
          {props.showVerificationStep && (
            <TitleVerificationStep
              email={email}
              props={props}
              setEmail={setEmail}
            />
          )}
          {!props.showVerificationStep && <TitlePopUp />}
        </div>
        {!props.showVerificationStep && (
          <>
            <div
              className={`flex items-center rounded-2xl border ${
                errorMessage
                  ? "border border-red-500"
                  : "border border-gray-300"
              } px-4 py-2 transition-all duration-200 focus-within:border-blue-500 focus-within:bg-transparent`}
            >
              <Icons.mail className="size-6" stroke="#AAA" />
              <input
                type="email"
                placeholder="Nhập địa chỉ email"
                value={email}
                onChange={handleEmailChange}
                className={`flex-1 border-none bg-transparent text-black placeholder:text-secondary-60 focus:outline-none focus:ring-0`}
              />
            </div>
            {errorMessage && (
              <div className="flex items-center gap-1">
                <Icons.CircleAlert
                  className="size-4"
                  fill="#FB1818"
                  stroke="#FFF"
                />
                <span className="text-xs font-normal text-red-500">
                  {errorMessage}
                </span>
              </div>
            )}
            <button
              disabled={loading}
              onClick={handleSignin}
              className={`${cn(
                buttonVariants({
                  size: "xl",
                })
              )} w-full !rounded-2xl !bg-primary-base`}
            >
              {loading && (
                <Icons.spinner className="mr-4 size-6 animate-spin" />
              )}
              <span>Gửi mã xác nhận</span>
            </button>
          </>
        )}
        {props.showVerificationStep && (
          <VerificationStep
            email={email}
            callBackUrl={props.callBackUrl}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default SignInWithEmail;
