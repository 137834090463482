import { handleScrollSearchBox } from "@/components/home/home-search";
import ErrorMessage from "./ErrorMessage";
import { Icons } from "@/components/icons";
import * as Popover from "@radix-ui/react-popover";
import React, { Dispatch, SetStateAction, useState } from "react";

interface InputItem {
  children: React.ReactElement;
  content: React.ReactElement;
  open: boolean;
  modal?: boolean;
  isClear?: boolean;
  isError?: boolean;
  className?: string;
  helperText?: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClick?: () => void;
  onClear?: () => void;
}
const InputItem: React.FC<InputItem> = ({
  onClear,
  modal = false,
  className = "",
  helperText = "",
  isError = false,
  children,
  content,
  isClear = false,
  onClick,
  open,
  setOpen,
}) => {
  return (
    <Popover.Root open={open} onOpenChange={setOpen} modal={modal}>
      <Popover.Trigger className="w-full" asChild>
        <div className={`flex w-full flex-col ${isError ? "gap-2" : ""} `}>
          <div
            onClick={(event) => {
              event.preventDefault();
              if (onClick) onClick();
              handleScrollSearchBox();
              setOpen(true);
            }}
            className={
              `w-full rounded-full bg-white p-4 shadow ${
                open ? "border-2 border-blue-500" : "border border-secondary-40"
              } inline-flex items-center justify-start gap-2 ` + className
            }
          >
            {children}
            {isClear && (
              <Icons.close
                className="relative size-6 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  if (onClear) onClear();
                }}
              />
            )}
          </div>
          {isError && <ErrorMessage text={helperText} />}
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="z-[999]" sideOffset={10} asChild>
          <div>{content}</div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default InputItem;
