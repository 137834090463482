import { sendEventGA } from "@/lib/google/google-analytics";
import { usePathname } from "next/navigation";
import { useEffect } from "react";

export const useTrackViewItineraryInput = () => {
  useEffect(() => {
    sendEventGA("iti_create_view", {});
  }, []);
};
export const useTrackViewTravelActDetail = (properties: {
  city: string;
  place: string;
  country: string;
  status: "initinerary" | "outitinerary";
}) => {
  const pathname = usePathname();
  useEffect(() => {
    if (!!properties.place) {
      sendEventGA("iti_act_detail_view", { ...properties });
    }
  }, [properties.place]);
};
export const useTrackViewCountryPage = (country: string) => {
  useEffect(() => {
    if (country) sendEventGA("country_page_view", { country });
  }, [country]);
};
export const useTrackViewItiInputStepTwo = (properties: {
  country: string;
  city: string;
  city_amount: number;
  interest: string;
}) => {
  useEffect(() => {
    if (!!properties.country)
      sendEventGA("act_select_page_view", { ...properties });
  }, [properties.country]);
};

export const trackClickCountryContinue = (properties: {
  city: string;
  city_amount: number;
}) => {
  sendEventGA("country_page_continue_click", { ...properties });
};

export const trackClickItiInputStepTwo = (properties: {
  country: string;
  city: string;
  duration: number;
  start_date: string;
  end_date: string;
  interest: string;
  interest_amount: number;
}) => {
  sendEventGA("iti_create_continue_click", { ...properties });
};
export const trackClickCityDetail = (properties: { city: string }) => {
  sendEventGA("country_page_city_detail_click", { ...properties });
};
export const trackClickGenerateIti = (properties: {
  city: string;
  country: string;
  act_amount: number;
}) => {
  sendEventGA("act_select_continue_click", { ...properties });
};
export const trackClickActDetail = (properties: {
  city: string;
  place: string;
}) => {
  sendEventGA("act_select_act_detail_click", { ...properties });
};

export const trackClickSaveIti = (properties: {
  city: string;
  country: string;
}) => {
  sendEventGA("iti_list_save_click", { ...properties });
};

export const trackClickItiCreateBack = () => {
  sendEventGA("iti_create_back_click", {});
};
export const trackClickCountryPageBack = (country: string) => {
  sendEventGA("country_page_back_click", { country });
};
export const trackClickActSelectBack = (country: string, city: string) => {
  sendEventGA("act_select_back_click", { country, city });
};
export const trackClickActSelectCity = (country: string, city: string) => {
  sendEventGA("act_select_city_click", { country, city });
};
