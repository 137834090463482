import { format } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "react-day-picker";

type DayInfo = {
  label: string;
  day: string;
  fullDay: any;
};

function toIsoString(date: Dayjs) {
  const tzo = -date.toDate().getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const pad = function (num) {
    return (num < 10 ? "0" : "") + num;
  };

  return (
    date.year() +
    "-" +
    pad(date.month() + 1) +
    "-" +
    pad(date.date()) +
    "T" +
    pad(date.hour()) +
    ":" +
    pad(date.minute()) +
    ":" +
    pad(date.second()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" +
    pad(Math.abs(tzo) % 60)
  );
}

function toUtcIsoString(date: Dayjs) {
  const tzo = -date.toDate().getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const pad = function (num) {
    return (num < 10 ? "0" : "") + num;
  };

  return (
    date.year() +
    "-" +
    pad(date.month() + 1) +
    "-" +
    pad(date.date()) +
    "T" +
    pad(date.hour()) +
    ":" +
    pad(date.minute()) +
    ":" +
    pad(date.second()) +
    "Z"
  );
}

export const formatDate = (date) => {
  const year = date?.getFullYear();
  const month = date?.getMonth().toString(); // Tháng bắt đầu từ 0
  const day = date?.getDate().toString();
  return `${year}-${month}-${day}`;
};

export const formatCaption = (date) => {
  return `Tháng ${format(date, "MM yyyy")}`;
};

const handleDateSelection = (
  dateSelected: Date | DateRange | undefined,
  item,
  priceByDay
): number => {
  // Lấy packageId từ item.id
  const packageId = item.id;

  // Định dạng ngày thành YYYY-MM-DD
  const formattedDate = formatDate(dateSelected);

  // Tạo key theo định dạng packageId:YYYY-MM-DD
  const dayKey = `${packageId}:${formattedDate}`;

  // Lấy giá từ priceByDay
  const price = Number(priceByDay[dayKey]) === -1 ? 0 : priceByDay[dayKey];

  console.log(
    `PP Giá tiền cho ngày ${formattedDate} là:`,
    price,
    priceByDay,
    dayKey
  );

  // Sử dụng giá này để hiển thị hoặc cho các xử lý tiếp theo
  return price;
};

export const getWeekFromDate = (
  inputDate: Date | null,
  n: number
): DayInfo[] => {
  const daysOfWeek: string[] = [
    "Chủ Nhật",
    "Thứ 2",
    "Thứ 3",
    "Thứ 4",
    "Thứ 5",
    "Thứ 6",
    "Thứ 7",
  ];
  const result: DayInfo[] = [];

  let currentDate = new Date();
  if (inputDate) {
    currentDate = new Date(inputDate);
  }
  const startOfWeek = new Date(currentDate);

  for (let i = 0; i < n; i++) {
    const newDate = new Date(startOfWeek);
    newDate.setDate(startOfWeek.getDate() + i);
    const dayOfWeekLabel = daysOfWeek[newDate.getDay()];
    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const formattedDate = `${day}/${month}`;
    result.push({
      label: dayOfWeekLabel,
      day: formattedDate,
      fullDay: newDate,
    });
  }

  return result;
};

const DateUtils = {
  toIsoString,
  toUtcIsoString,
  handleDateSelection,
};

export default DateUtils;
