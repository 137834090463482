import useItineraryQuestionFormV3Store from "../hooks/itinerary-question-form-v3-store";
import ErrorMessage from "./ErrorMessage";
import InputItem from "./InputItem";
import { Icons } from "@/components/icons";
import { Calendar } from "@/components/ui/calendar";
import { Switch } from "@/components/ui/switch";
import useIsDesktop from "@/hooks/useIsDesktop";
import { formatCaption } from "@/lib/utils/date-utils";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-day-picker";

interface TimeInputProps {
  checkValid?: boolean;
  scrollCenter?: boolean;
}

const ButtonApply = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="w-auto rounded-full bg-action px-[52px] py-3 text-sm font-semibold text-white"
    >
      Áp dụng
    </button>
  );
};

const TimeInput = ({
  checkValid = false,
  scrollCenter = true,
}: TimeInputProps) => {
  const {
    dateRange: date,
    totalDays,
    isExactDay,
    setDateRange: setDate,
    setTotalDays,
    setIsExactDay,
  } = useItineraryQuestionFormV3Store();
  const isDesktop = useIsDesktop();
  const inputRef = useRef<HTMLInputElement>(null);
  const [active, setActive] = useState(false);
  const [isError, setIsError] = useState(false);
  const contentRef = useRef<any>(null);

  useEffect(() => {
    if (active && scrollCenter) {
      // Scroll the content into view
      if (contentRef.current) {
        contentRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [active]);

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        focusInput();
      }, 100);
    }
  }, [active]);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  function checkRangeValid(date1: Date, date2: Date): boolean {
    const millisecondsPerDay = 1000 * 60 * 60 * 24; // Milliseconds in a day

    // Calculate the difference in milliseconds between the two dates
    const differenceMs = Math.abs(date1.getTime() - date2.getTime());

    // Convert the difference from milliseconds to days
    const differenceDays = Math.ceil(differenceMs / millisecondsPerDay);

    // Check if the difference is less than or equal to 10 days
    return differenceDays < 10;
  }

  function calculateTotalDays(input) {
    const { from, to } = input;

    const fromDate = new Date(from);
    const toDate = new Date(to);
    const timeDifference = toDate.getTime() - fromDate.getTime();

    const totalDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return totalDays + 1;
  }

  const handleChangeDay = (dateRange: DateRange) => {
    if (dateRange?.from && dateRange?.to) {
      const totalDaySelect = calculateTotalDays(dateRange);
      const isRangeValid = checkRangeValid(dateRange.from, dateRange.to);
      if (!isRangeValid) {
        return setIsError(true);
      }
      if (totalDaySelect > 0) {
        setTotalDays(totalDaySelect);
      }
    }
    setIsError(false);
    setDate(dateRange);
  };
  function reduceTotalDays() {
    if (totalDays > 0) {
      setTotalDays((prevState1) => prevState1 - 1);
    }
  }

  function increaseTotalDays() {
    if (totalDays < 10) {
      setTotalDays((prevState1) => prevState1 + 1);
    }
  }

  const handleClose = () => {
    if (!isError) {
      setActive(false);
    }
  };

  const content = (
    <div className="relative z-10 w-screen max-w-[343px] rounded-3xl border border-gray-100 bg-white shadow md:max-w-[588px]">
      <div className="p-4">
        <div className="flex flex-col items-center justify-start gap-4">
          {!isExactDay && (
            <div className="flex flex-col items-center justify-start gap-2">
              <div className="text-sm font-semibold leading-tight text-indigo-950 md:text-base">
                Bạn đi bao lâu?
              </div>
              <div className="inline-flex items-center justify-start gap-4">
                <button
                  disabled={totalDays <= 0}
                  onClick={() => reduceTotalDays()}
                  className="flex size-5 cursor-pointer items-center justify-center"
                >
                  <Icons.itineraryMinus
                    className="relative size-5"
                    fill={totalDays <= 0 ? undefined : "#517CFF"}
                  />
                </button>
                <div className="text-center text-xl font-semibold leading-loose text-blue-500 md:text-2xl">
                  <div className="text-center text-xl font-semibold leading-loose text-blue-500 md:text-2xl">
                    <div>
                      {totalDays !== undefined ? (
                        <>
                          <span translate="no">{totalDays} </span>
                          <span>ngày</span>
                        </>
                      ) : undefined}
                    </div>
                  </div>
                </div>
                <button
                  disabled={totalDays >= 10}
                  onClick={() => increaseTotalDays()}
                  className="flex size-5 cursor-pointer items-center justify-center"
                >
                  <Icons.itineraryPlus
                    fill={totalDays >= 10 ? undefined : "#517CFF"}
                    className="relative size-5 "
                  />
                </button>
              </div>
            </div>
          )}
          <div className="inline-flex items-center justify-start gap-4">
            <div className="inline-flex flex-col items-center justify-center">
              <div className="text-sm font-semibold leading-tight text-slate-900 md:text-base">
                Chọn ngày cụ thể
              </div>
              {isExactDay && (
                <div className="text-center text-base font-normal leading-tight text-slate-600">
                  <span>Tổng số ngày: </span>
                  <span>{totalDays ?? "--"}</span>
                </div>
              )}
            </div>
            <div>
              <Switch
                checked={isExactDay}
                onCheckedChange={(value) => {
                  setTotalDays(0);
                  setDate({ from: undefined, to: undefined });
                  setIsExactDay(value);
                }}
              />
            </div>
          </div>

          {isExactDay && (
            <div className="mt-2 flex-col">
              <div className="items-center justify-center">
                <Calendar
                  initialFocus
                  mode="range"
                  showOutsideDays={false}
                  defaultMonth={date?.from}
                  disabled={(date) => {
                    const isPastDate = dayjs(date).isBefore(new Date(), "day");
                    return isPastDate;
                  }}
                  selected={date}
                  onSelect={handleChangeDay}
                  numberOfMonths={isDesktop ? 2 : 1}
                  className="p-0"
                  formatters={{
                    formatCaption: formatCaption,
                  }}
                />
              </div>
            </div>
          )}

          {isError && (
            <ErrorMessage text="Vui lòng chọn trong khoảng tối đa 10 ngày" />
          )}

          <ButtonApply onClick={handleClose} />
        </div>
      </div>
    </div>
  );

  return (
    <InputItem
      content={content}
      open={active}
      setOpen={setActive}
      isClear={!!totalDays && active}
      onClear={() => {
        setTotalDays(0);
        setDate({
          from: undefined,
          to: undefined,
        });
      }}
      isError={checkValid && totalDays == 0}
      helperText="Bạn chưa chọn thời gian đi. Hãy nhập thông tin để Laka tiếp tục giúp bạn nhé!"
    >
      <div ref={contentRef} className="flex size-full items-center gap-2">
        {!isExactDay && (
          <>
            <Icons.itineraryInputCalender
              className="relative "
              stroke={active ? "#517CFF" : "#111B42"}
              width={isDesktop ? 24 : 20}
              height={isDesktop ? 24 : 20}
            />
            <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start">
              <div className="text-sm font-semibold leading-normal text-indigo-950 md:text-base">
                Bạn đi trong bao lâu?
              </div>
              <div
                className={`line-clamp-1 text-left text-sm font-medium leading-normal ${
                  totalDays > 0 ? "text-zinc-600" : "text-[#D8DAE2]"
                }  md:text-base`}
              >
                {totalDays > 0 ? (
                  <span>
                    <span translate="no">{totalDays} </span>
                    ngày
                  </span>
                ) : (
                  "Thêm thời gian"
                )}
              </div>
            </div>
          </>
        )}
        {isExactDay && (
          <div className="flex w-full ">
            <div className="flex w-full items-center gap-2">
              <Icons.itineraryInputCalender
                stroke={!date?.from ? "#517CFF" : "#111B42"}
                className="relative "
                width={isDesktop ? 24 : 20}
                height={isDesktop ? 24 : 20}
              />
              <div className="flex flex-col flex-wrap ">
                <div className="text-sm font-semibold leading-normal text-indigo-950 md:text-base">
                  Ngày đi*
                </div>
                <div className="inline-flex items-start justify-start gap-1 self-stretch">
                  <div className="shrink grow basis-0 text-left text-sm font-medium leading-normal text-zinc-600 md:text-base">
                    {date?.from ? (
                      dayjs(date.from).format("DD/MM/YYYY")
                    ) : (
                      <div
                        className={`${
                          !date?.from ? "text-gray-300" : "text-gray-400"
                        }  text-sm font-medium leading-normal md:text-base`}
                      >
                        Thêm ngày
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="h-8 border-l-2 p-1"></div>

            <div className="flex w-full items-center gap-2">
              <Icons.itineraryInputCalender
                stroke={date?.from && !date?.to ? "#517CFF" : "#111B42"}
                className="relative"
                width={isDesktop ? 24 : 20}
                height={isDesktop ? 24 : 20}
              />
              <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start   ">
                <div className="text-sm font-semibold leading-normal text-indigo-950 md:text-base">
                  Ngày về*
                </div>
                <div className="inline-flex items-start justify-start gap-1 self-stretch">
                  <div className="shrink grow basis-0 text-left text-sm font-medium leading-normal text-zinc-600 md:text-base">
                    {date?.to ? (
                      dayjs(date.to).format("DD/MM/YYYY")
                    ) : (
                      <div
                        className={`${
                          date?.from && !date?.to
                            ? "text-gray-300"
                            : "text-gray-400"
                        }  text-sm font-medium leading-normal md:text-base`}
                      >
                        Thêm ngày
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </InputItem>
  );
};

export default TimeInput;
