import { Icons } from "@/components/icons";
import React from "react";

const ErrorMessage = ({text=""}) => {
  return (
    <div className="flex flex-row items-start gap-2 text-xs font-normal text-[#F44622]">
      <span className="size-3.5">
        <Icons.iconNotiError className="size-3.5" />
      </span>
      <span>
        {text}
      </span>
    </div>
  );
};

export default ErrorMessage;
