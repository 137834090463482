import { create } from "zustand";

interface PhotoPreviewHook {
  isOpen: boolean;
  index: number;
  tabs: { key: string; name: string; images: { url: string; alt: string }[] }[];
  activeTab: string;
  images: { url: string; alt: string }[];
  setActiveTab: (setActiveTab: string) => void;
  onChangeSlider: (index: number) => void;
  onOpen: (images: { url: string; alt: string }[], index?: number) => void;
  onClose: () => void;
  openTabs: (
    activeTab: string,
    images: { url: string; alt: string }[],
    tabs?: {
      key: string;
      name: string;
      images: { url: string; alt: string }[];
    }[]
  ) => void;
}

const usePhotoPreview = create<PhotoPreviewHook>((set) => ({
  isOpen: false,
  index: 0,
  activeTab: "-1",
  tabs: [],
  images: [],
  setActiveTab: (tab) => set({ activeTab: tab }),
  onChangeSlider: (index) => set({ index }),
  onOpen: (images, index) => set({ images, index, isOpen: true }),
  onClose: () => set({ isOpen: false }),
  openTabs: (index, images, tabs) =>
    set({
      activeTab: index,
      images: images,
      tabs: tabs,
      isOpen: true,
      index: 0,
    }),
}));

export default usePhotoPreview;
