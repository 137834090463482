"use client";

import useAppStore from "./provider/app-store";
import { init, track, setUserId } from "@amplitude/analytics-browser";
import { useEffect } from "react";

const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;

export const trackAmplitudeEvent = (eventName, data) => {
  try {
    track(eventName, data);
  } catch (error) {
    console.error("Amplitude error:", error);
  }
};

const AmplitudeProvider = () => {
  const { user } = useAppStore();
  useEffect(() => {
    if (!AMPLITUDE_API_KEY) return;
    init(AMPLITUDE_API_KEY, undefined, {
      defaultTracking: {
        sessions: true,
      },
    });
    if (user?.id) setUserId(user.id);
  }, [user]);

  return <></>;
};

export default AmplitudeProvider;
