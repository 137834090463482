"use client";

import useIsDesktop from "../../../../../hooks/useIsDesktop";
import countriesData from "../../../../../lib/data/countries-data";
import { Icons } from "@/components/icons";
import NextImage from "@/components/ui/next-image";
import { PACKAGE_HIGHLIGHT_OPTIONS } from "@/lib/constants/ecommerce";
import { CDN_URL } from "@/lib/constants/env";
import { CategoryType } from "@/lib/enums/ecom-enum";
import { formatNumberVND } from "@/lib/utils/format-number";
import { useRouter } from "next/navigation";

export const getLabelCityByCode = (code) => {
  if (!code) return "";

  const codes = code.split(",");
  const allCountries = countriesData().getAll();

  const labels = codes.map((singleCode) => {
    const country = allCountries.find(
      (item) => item.value === singleCode.trim()
    );
    return country ? country.label : singleCode.trim();
  });

  return labels.join(", ");
};
interface AidItemProps {
  item: any;
  classNames?: string;
  imagePriority?: boolean;
  showCombo?: boolean;
  onClick?: () => void;
}

const AidItem: React.FC<AidItemProps> = ({
  item,
  classNames = "w-[164px]",
  imagePriority = false,
  showCombo = false,
  onClick,
}) => {
  const router = useRouter();
  const renderTourOrTransportation = (departureCity, destinationCity) => {
    if (departureCity && destinationCity) {
      return departureCity === destinationCity
        ? `${departureCity}`
        : `${departureCity},${destinationCity}`;
    }
    return ""; // Trả về chuỗi rỗng nếu không có thành phố
  };
  const isDeskTop = useIsDesktop();
  let salePriceTag = 0;
  if (item.lowestPrice !== 0 && item.publicPrice !== 0) {
    salePriceTag = Math.round(
      ((item.publicPrice - item.lowestPrice) / item.publicPrice) * 100
    );
  }
  const renderCategory = (data) => {
    const {
      departureCity,
      destinationCity,
      category,
      coverageArea,
      searchableLocationCities,
    } = data;

    if (!category) return "";
    const { category: categoryType } = category;
    if (
      categoryType === CategoryType.CONECTIVITY ||
      categoryType === CategoryType.CONVENIENCE
    ) {
      return getLabelCityByCode(coverageArea);
    }
    if (
      categoryType === CategoryType.TOUR ||
      categoryType === CategoryType.TRANSPORTATION
    ) {
      return renderTourOrTransportation(departureCity, destinationCity);
    }
    return searchableLocationCities;
  };
  const photoUrl =
    item.photo || (item.photos?.length ? item.photos[0]?.photoUrl : "");
  return (
    <div
      key={item.id}
      onClick={() => {
        if (!onClick) router.push(`/activity/${item.slug}`);
        else onClick();
      }}
      className="inline-flex size-full h-full cursor-pointer flex-col items-start justify-start"
    >
      <div
        className={
          "aspect-[4/3] relative min-h-[132px] h-[132px] rounded-t-2xl md:h-[200px] md:min-h-[200px] md:w-[258px] " +
          classNames
        }
      >
        <NextImage
          width={258}
          height={200}
          className="size-full rounded-t-2xl object-cover"
          src={`${CDN_URL}${photoUrl}`}
          alt={item.title}
          vertical={false}
          priority={imagePriority}
        />
        <div className="absolute bottom-0 w-full bg-opacity-50 bg-gradient-to-t from-[#000000C4] to-[#0F183B00] px-3 py-0.5 text-xs font-normal leading-5 text-white md:py-[6px] md:text-sm ">
          <div className="flex items-center gap-1 ">
            <Icons.mapPinWhite className="size-5" />
            <div className="line-clamp-1"> {renderCategory(item)}</div>
          </div>
        </div>
      </div>

      <div
        className={`h-full rounded-b-2xl border border-line bg-white md:w-[258px] ${classNames}`}
      >
        <div className="flex size-full flex-col justify-between gap-2 p-2 md:p-3">
          <div className="flex w-full flex-col gap-[2px]">
            <div className="line-clamp-2 text-wrap text-sm font-semibold text-secondary-100 md:text-base">
              {item?.title}
            </div>
            <div className="inline-flex items-start justify-start gap-1 text-xs font-normal md:text-sm ">
              <span>{item?.category?.name}</span>
              <span>
                {item?.minDurationInMinutes > 0 &&
                item?.maxDurationInMinutes > 0 &&
                (item?.category?.category === CategoryType.TOUR ||
                  item?.category?.category === CategoryType.TRANSPORTATION)
                  ? item?.minDurationInMinutes === item?.maxDurationInMinutes
                    ? ` • ${item?.minDurationInMinutes} tiếng`
                    : ` • ${item?.minDurationInMinutes}-${item?.maxDurationInMinutes} tiếng`
                  : ""}
              </span>
            </div>

            {!!item.ratingCount && (
              <>
                <div className="flex w-full items-center justify-start gap-1">
                  <div>
                    <Icons.starCardAid className="relative size-4" />
                  </div>
                  <span className="text-sm font-medium leading-tight text-orange-400">
                    {item.ratingAverage.toFixed(1) || ""}
                  </span>
                  {/* <span className="text-sm font-medium leading-tight text-slate-900">
                    {" "}
                  </span> */}
                  <span className="text-sm font-medium leading-tight text-slate-600">
                    (<span>{item.ratingCount}</span>)
                  </span>
                </div>
              </>
            )}
          </div>
          <div>
            {showCombo && (
              <>
                <div className="pb-1 text-base font-semibold leading-normal text-[#0f183b] md:pb-2">
                  {formatNumberVND(item.lowestPrice)}
                </div>
                <div className="mdh-[45px] h-[52px]">
                  {Number(item.lowestPrice) > Number(item.comboPrice) && (
                    <div className="mdh-[45px] relative h-[52px] flex-col items-start justify-start rounded-xl bg-[#fff4de] p-[6px] md:px-3 md:py-2">
                      <div className="flex items-center gap-1">
                        <span className="text-xs font-bold leading-none text-[#f44622]">
                          chỉ còn
                        </span>
                        <span className="text-xs font-bold leading-none text-[#f44622]">
                          {formatNumberVND(item.comboPrice)}
                        </span>
                      </div>
                      <div className="inline-flex items-center justify-start gap-1">
                        <div className="text-nowrap text-[11px] font-medium leading-none text-[#0f183b] md:text-xs">
                          khi mua kèm Combo
                        </div>
                        <Icons.ComboTicket className="relative size-4 overflow-hidden" />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {!showCombo && (
              <>
                <div className="inline-flex w-full flex-wrap items-center justify-start gap-1 text-nowrap">
                  <span className="text-sm font-normal leading-tight text-slate-600">
                    Từ
                  </span>
                  <span className="text-sm font-semibold leading-tight text-blue-500">
                    {formatNumberVND(item.lowestPrice)}
                  </span>
                </div>
                {isDeskTop && (
                  <div className="inline-flex h-6 w-full items-start justify-start gap-1 overflow-hidden">
                    {item.highlightType && (
                      <div className=" inline-flex h-6 items-start justify-start gap-2.5 rounded bg-[#fcdda4] px-1 py-0.5">
                        <div className=" text-sm font-normal leading-tight text-[#0f183b]">
                          {
                            PACKAGE_HIGHLIGHT_OPTIONS.find(
                              (option) => option.value === item.highlightType
                            )?.title
                          }
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AidItem;
