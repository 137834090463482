import { signIn } from "next-auth/react";
import React, { useEffect, useState } from "react";
import { validateTokenEmail } from "../../../../actions/login";
import { Icons } from "../../../../components/icons";
import { buttonVariants } from "../../../../components/ui/button";
import { cn } from "../../../../lib/utils";

interface VerificationStepProps {
  email: string;
  callBackUrl: string;
  loading: boolean;
}

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes}:${secs.toString().padStart(2, "0")}`;
};

export const VerificationStep: React.FC<VerificationStepProps> = ({
  email,
  callBackUrl,
  loading,
}) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [errorMessage, setErrorMessage] = useState("");
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds
  const [expired, setExpired] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);

  useEffect(() => {
    if (!loading) {
      setResendDisabled(true);
    }
  }, [loading]);

  useEffect(() => {
    if (resendDisabled) {
      const timer = setTimeout(() => {
        setResendDisabled(false);
      }, 60000); // 60 seconds
      return () => clearTimeout(timer);
    }
  }, [resendDisabled]);

  useEffect(() => {
    if (timeLeft <= 0) {
      setExpired(true);
      setOtp(Array(6).fill(""));
      return;
    }
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleOtpChange = (index: number, value: string) => {
    setErrorMessage("");
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      if (value && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        nextInput?.focus();
      } else if (!value && index > 0) {
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        prevInput?.focus();
      }
      setOtp(newOtp);
    }
  };

  const onReady = async () => {
    const token = otp.join("");
    const isValidOtp = await validateTokenEmail(token, email);
    console.log(isValidOtp, "TsisValidOtp");
    
    if (!isValidOtp.valid) {
      setErrorMessage("Mã vừa nhập không chính xác, vui lòng kiểm tra lại.");
      return;
    }

    const res = await fetch(
      `/api/auth/callback/email?email=${encodeURIComponent(
        email
      )}&token=${token}${callBackUrl ? `&callbackUrl=${callBackUrl}` : "/"}`
    );

    console.log(res, "TSres");
    if (res.ok) {
      window.location.href = callBackUrl;
    } else {
      setErrorMessage("Mã vừa nhập không chính xác, vui lòng kiểm tra lại.");
      setOtp(Array(6).fill(""));
    }
  };

  const resendOtp = async () => {
    setErrorMessage("");
    setResendDisabled(true); // Disable the resend button
    setOtp(Array(6).fill(""));
    try {
      // Gọi API gửi lại OTP
      const res = await signIn("email", {
        email: email,
        redirect: false,
      });
      console.log(res, "TSres123");
      if (res?.error) {
        if (res?.url) {
          window.location.replace(res.url);
        }
      }
      setTimeLeft(300); // Reset the timer
      setExpired(false);
    } catch (error) {
      setErrorMessage("Không thể gửi lại mã xác minh. Vui lòng thử lại.");
    }
  };

  return (
    <div className="flex flex-col gap-4 ">
      <div className="flex justify-center gap-2 px-6">
        {otp.map((value, index) => (
          <input
            key={index}
            id={`otp-input-${index}`}
            type="tel"
            value={value}
            onChange={(e) => handleOtpChange(index, e.target.value)}
            className={`otp-input ${
              errorMessage
                ? "border-red-500"
                : value
                ? "border-secondary-100"
                : "border-[#D8DAE2]"
            }  h-[52px] w-[48px] rounded-xl border bg-transparent text-center text-xl font-semibold focus:outline-none focus:ring-0 md:size-[60px]`}
            maxLength={1}
          />
        ))}
      </div>
      {errorMessage && (
        <div className="flex items-center justify-start gap-1">
          <Icons.CircleAlert className="size-4" fill="#FB1818" stroke="#FFF" />
          <span className="text-xs text-red-500">{errorMessage}</span>
        </div>
      )}
      <div className="flex items-center justify-between gap-1 pt-1">
        {expired ? (
          <span className=" text-sm text-[#888DA0]">
            Mã xác minh đã hết hạn
          </span>
        ) : (
          <div className="text-sm">
            <span className="text-[#888DA0]">
              Mã xác minh có hiệu lực trong vòng{" "}
              <span className="text-black" translate="no"> {formatTime(timeLeft)}</span>
            </span>
          </div>
        )}
        <span
          onClick={resendOtp}
          className={`cursor-pointer text-nowrap text-sm text-blue-1 underline ${
            resendDisabled ? "cursor-not-allowed opacity-50" : ""
          }`}
          style={{ pointerEvents: resendDisabled ? "none" : "auto" }} // Disable pointer events when disabled
        >
          Gửi lại
        </span>
      </div>
      <button
        onClick={onReady}
        className={`${cn(
          buttonVariants({
            size: "xl",
          })
        )} w-full !rounded-2xl`}
        disabled={otp.join("").length !== 6}
        style={{ backgroundColor: otp.join("").length === 6 ? "" : "#AAA" }}
      >
        <span>Xác nhận</span>
      </button>
    </div>
  );
};
