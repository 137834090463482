const FEATURE_FLAG_AUTHENTICATION =
  process.env.FEATURE_FLAG_AUTHENTICATION ||
  process.env.NEXT_PUBLIC_FEATURE_FLAG_AUTHENTICATION ||
  'false';
const FEATURE_FLAG_SEO =
  process.env.FEATURE_FLAG_SEO ||
  process.env.NEXT_PUBLIC_FEATURE_FLAG_SEO ||
  'false';
const FEATURE_FLAG_POST_EDIT =
  process.env.FEATURE_FLAG_POST_EDIT ||
  process.env.NEXT_PUBLIC_FEATURE_FLAG_POST_EDIT ||
  'false';
const FEATURE_FLAG_PUBLIC_SIGNUPS =
  process.env.FEATURE_FLAG_PUBLIC_SIGNUPS ||
  process.env.NEXT_PUBLIC_FEATURE_FLAG_PUBLIC_SIGNUPS ||
  'false';
const FEATURE_FLAG_NSFW_UPLOAD_FILTER =
  process.env.FEATURE_FLAG_NSFW_UPLOAD_FILTER ||
  process.env.NEXT_PUBLIC_FEATURE_NSFW_UPLOAD_FILTER ||
  'false';
const FEATURE_FLAG_AI_UPLOAD_FILTER =
  process.env.FEATURE_FLAG_AI_UPLOAD_FILTER ||
  process.env.NEXT_PUBLIC_FEATURE_AI_UPLOAD_FILTER ||
  'false';
const FEATURE_FLAG_JOBS_NAV_LINK =
  process.env.FEATURE_FLAG_JOBS_NAV_LINK ||
  process.env.NEXT_PUBLIC_FEATURE_FLAG_JOBS_NAV_LINK ||
  'false';
const FEATURE_FLAG_FACEBOOK_LOGIN =
  process.env.FEATURE_FLAG_FACEBOOK_LOGIN ||
  process.env.NEXT_PUBLIC_FEATURE_FLAG_FEATURE_FLAG_FACEBOOK_LOGIN ||
  'false';

enum FeatureFlag {
  Authentication,
  Seo,
  PostEdit,
  PublicSignups,
  NsfwUploadFilter,
  AiUploadFilter,
  JobsNavLink,
  FacebookLogin
}

const featureFlagEnabled = (flag: FeatureFlag): boolean => {
  if (flag === FeatureFlag.Authentication)
    return FEATURE_FLAG_AUTHENTICATION.toLowerCase() === 'true' || false;

  if (flag === FeatureFlag.Seo)
    return FEATURE_FLAG_SEO.toLowerCase() === 'true' || false;

  if (flag == FeatureFlag.PostEdit)
    return FEATURE_FLAG_POST_EDIT.toLowerCase() === 'true' || false;

  if (flag == FeatureFlag.PublicSignups)
    return FEATURE_FLAG_PUBLIC_SIGNUPS.toLowerCase() === 'true' || false;

  if (flag == FeatureFlag.NsfwUploadFilter)
    return FEATURE_FLAG_NSFW_UPLOAD_FILTER.toLowerCase() === 'true' || false;

  if (flag == FeatureFlag.AiUploadFilter)
    return FEATURE_FLAG_AI_UPLOAD_FILTER.toLowerCase() === 'true' || false;

  if (flag == FeatureFlag.JobsNavLink)
    return FEATURE_FLAG_JOBS_NAV_LINK.toLowerCase() === 'true' || false;

  if (flag == FeatureFlag.FacebookLogin)
    return FEATURE_FLAG_FACEBOOK_LOGIN.toLowerCase() === 'true' || false;

  return false;
};

export { featureFlagEnabled, FeatureFlag };
