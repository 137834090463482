import { env } from "@/env.mjs";
import { ClassValue, clsx } from "clsx";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import updateLocale from "dayjs/plugin/updateLocale";
import { twMerge } from "tailwind-merge";
// Load and update the Vietnamese locale
import "dayjs/locale/vi";
import {
  CategoryType,
  ConfirmationTimeType,
  ValidityDateType,
  ValidityDateTypeTranslate,
  ValidityLakaInputDateType,
} from "./enums/ecom-enum";
import { PlaceReview, TravelPlace } from "@prisma/client";
import countriesData from "./data/countries-data";
import { I18nCountries } from "./i18n/i18n-countries";
import { MasterData } from "./data/master-data";

dayjs.extend(localeData);
dayjs.extend(updateLocale);

dayjs.locale("vi");

// Customize the Vietnamese locale
dayjs.updateLocale("vi", {
  weekdaysShort: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(input: string | number): string {
  const date = new Date(input);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}
export function formatDateByWeek(input: Date): string {
  const date = new Date(input);
  return date.toLocaleDateString("vi-VN", {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  });
}
export function formatDateSelection(input: Date, format?: string): string {
  const date = dayjs(input);
  const now = dayjs();
  let formatString = "DD/MM/YYYY";
  if (date.isAfter(now, "day")) {
    formatString += ", YYYY";
  }
  return date.format(format || formatString);
}
export function formatFullDate(input: Date): string {
  const date = new Date(input);
  return date.toLocaleDateString("vi-VN", {
    weekday: undefined,
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

export function formatDateToVietnamese(dateString) {
  const months = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];

  return `${day} thg ${month}`;
}

export function absoluteUrl(path: string) {
  return `${env.NEXT_PUBLIC_APP_URL}${path}`;
}

export function getFileType(file) {
  const fileType = file.type;

  if (fileType.startsWith("video/")) {
    return "VIDEO";
  } else if (fileType.startsWith("image/")) {
    return "IMAGE";
  } else if (fileType.startsWith("application/pdf")) {
    return "PDF";
  } else {
    return "unknown";
  }
}
export function isValidJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function isDateWithinRange(
  date: Date,
  dates: { fromDate: Date; toDate: Date }[]
): boolean {
  for (const { fromDate, toDate } of dates) {
    if (date >= fromDate && date <= toDate) {
      return true;
    }
  }
  return false;
}

export const isDateAvailable = (date, availableDates) => {
  // 1. Chuyển đổi thành mảng các đối tượng Date
  // 2. Tạo Set để kiểm tra ngày nhanh hơn
  const availableDatesSet = new Set(
    availableDates.map((item) =>
      new Date(item.year, item.month, item.date).toDateString()
    )
  );
  return availableDatesSet.has(date.toDateString());
};

export function subtractHoursFromTime(
  hoursToSubtract: number,
  time: number = 2359
): string {
  const minutes = time % 100;
  const hours = (time - minutes) / 100;
  let totalMinutes = hours * 60 + minutes;
  totalMinutes -= hoursToSubtract * 60;
  let newHours = Math.floor(totalMinutes / 60) % 24;

  const newMinutes = totalMinutes % 60;
  if (totalMinutes < 0) {
    newHours = (24 + newHours) % 24;
  }

  // console.log("TTT ", time, hoursToSubtract, minutes, hours, totalMinutes, newHours, newMinutes);
  const formattedHours = String(newHours).padStart(2, "0");
  const formattedMinutes = String(newMinutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

export function subtractDaysFromDate(
  dateString: string,
  daysToSubtract: number
): string {
  // Parse the input date string (assuming the format is DD/MM/YYYY)
  const [day, month, year] = dateString.split("/").map(Number);
  const date = new Date(year, month - 1, day);
  date.setDate(date.getDate() - daysToSubtract);
  const newDay = String(date.getDate()).padStart(2, "0");
  const newMonth = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const newYear = date.getFullYear();

  return `${newDay}/${newMonth}/${newYear}`;
}
export const convertHoursToDaysAndHours = (hours) => {
  // Tính số ngày và số giờ còn lại
  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;

  // Tạo chuỗi kết quả
  let result = "";
  if (days > 0) {
    result += `${days} ngày`;
  }
  if (remainingHours > 0) {
    if (result) {
      result += " ";
    }
    result += `${remainingHours} giờ`;
  }

  return result || "0 giờ"; // Trả về "0 giờ" nếu không có ngày và giờ
};

export const getDateTitle = (bookingItem) => {
  let convertTitle;

  switch (bookingItem?.dateTitle) {
    case ValidityDateType.ACTIVATION:
      convertTitle = ValidityDateTypeTranslate.ACTIVATION;
      break;
    case ValidityDateType.PARTICIPATION:
      convertTitle = ValidityDateTypeTranslate.PARTICIPATION;
      break;
    case ValidityDateType.REDEMPTION:
      convertTitle = ValidityDateTypeTranslate.REDEMPTION;
      break;
    case ValidityDateType.RECEIVE:
      convertTitle = ValidityDateTypeTranslate.RECEIVE;
      break;
    case ValidityDateType.PICK_UP: // Nếu hai trường hợp có cùng một hành động, có thể kết hợp như thế này
      convertTitle = ValidityDateTypeTranslate.PICK_UP;
      break;
    case ValidityDateType.OTHER:
      convertTitle = bookingItem?.otherDateTitle;
      break;
    default:
      convertTitle = "Ngày sử dụng"; // Hoặc giá trị mặc định nào đó, tùy thuộc vào yêu cầu của bạn
      break;
  }

  return convertTitle; // Đảm bảo hàm trả về giá trị
};

export const dateTitleValiditySetting = (packageData) => {
  let convertTitle;

  switch (packageData?.validitySettingLakaInputDateType) {
    case ValidityLakaInputDateType.PURCHASE:
      convertTitle = ValidityDateTypeTranslate.PURCHASE;
      break;
    case ValidityLakaInputDateType.ACTIVATION:
      convertTitle = ValidityDateTypeTranslate.ACTIVATION;
      break;
    case ValidityLakaInputDateType.RECEIVE:
      convertTitle = ValidityDateTypeTranslate.RECEIVE;
      break;
    case ValidityLakaInputDateType.PICK_UP:
      convertTitle = ValidityDateTypeTranslate.PICK_UP;
      break;
    case ValidityDateType.OTHER:
      convertTitle = packageData?.validitySettingDateOtherTitle;
      break;
    default:
      convertTitle = ""; // Hoặc giá trị mặc định nào đó, tùy thuộc vào yêu cầu của bạn
      break;
  }

  return convertTitle; // Đảm bảo hàm trả về giá trị
};

export const getOrderConfirmationMessage = (
  confirmationTime,
  redeem = false
) => {
  let message = "";

  switch (confirmationTime) {
    case ConfirmationTimeType.INSTANT:
      message = redeem
        ? `
Đơn hàng sẽ được xác nhận ngay sau khi thanh toán thành công.
Bạn có thể sử dụng dịch vụ ngay lập tức`
        : `Đơn hàng sẽ được xác nhận ngay sau khi thanh toán thành công`;
      break;
    case ConfirmationTimeType.H24:
      message = ` 
Đơn hàng sẽ được xác nhận trong vòng tối đa 24 giờ sau khi thanh toán thành công`;
      break;
    case ConfirmationTimeType.H48:
      message = `
Đơn hàng sẽ được xác nhận trong vòng tối đa 48 giờ sau khi thanh toán thành công`;
      break;
    default:
      message = "Loại xác nhận không hợp lệ.";
  }

  return message;
};

export const isCategoryType = (value: any): value is CategoryType => {
  return Object.values(CategoryType).includes(value);
};
export const isLastReviewOlder = (
  placeData: TravelPlace & {
    reviews: PlaceReview[];
  }
): boolean => {
  // Check if there are any reviews
  if (!placeData?.reviews || placeData.reviews.length === 0) {
    return true; // No reviews to check
  }

  // Get the last review
  const lastReview = placeData.reviews[0];

  // Extract the review date and convert it to a Date object
  const reviewDate = new Date(Number(lastReview.time) * 1000); // Assuming `time` is a Unix timestamp in seconds

  // Get the current date and time
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = currentDate.getTime() - reviewDate.getTime();

  // Convert the difference to days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  // Return true if the difference is greater than 1 year
  return daysDifference > 365;
};

export function renderDurationText(duration) {
  if (!duration || typeof duration !== "string") return "";

  const lowerDuration = duration.toLowerCase();

  let result;
  if (lowerDuration.includes("half")) {
    result = "nửa ngày";
  } else if (lowerDuration.includes("full")) {
    result = "1 ngày";
  } else {
    result = duration;
  }

  return result;
}

export function prepareDataForApi(itineraryList) {
  const newItinerary = { ...itineraryList };

  // Map over each day in travelDays
  const newData = newItinerary.travelDays.map((day, index) => {
    const dayChanges = index + 1;

    let currentPartOfTheDay = ""; // Variable to store the current partOfTheDay
    const morningIndex = dayChanges * 1000;
    const afternoonIndex = dayChanges * 1000 + 200;
    const eveningIndex = dayChanges * 1000 + 400;

    // Filter and reduce activities to exclude labels
    const updatedAct = day.activities.reduce((updatedActivities, activity) => {
      if (activity.isLabel) {
        // Update currentPartOfTheDay based on the label
        if (activity.id.includes("morning-label")) {
          currentPartOfTheDay = "morning";
        } else if (activity.id.includes("afternoon-label")) {
          currentPartOfTheDay = "afternoon";
        } else if (activity.id.includes("evening-label")) {
          currentPartOfTheDay = "evening";
        }
      } else {
        // Assign partOfTheDay to activities that are not labels
        activity.partOfTheDay = currentPartOfTheDay;

        // Set the index based on the partOfTheDay
        if (currentPartOfTheDay === "morning") {
          activity.index = morningIndex + updatedActivities.length;
        } else if (currentPartOfTheDay === "afternoon") {
          activity.index = afternoonIndex + updatedActivities.length;
        } else if (currentPartOfTheDay === "evening") {
          activity.index = eveningIndex + updatedActivities.length;
        }

        // Add the activity to the result array
        updatedActivities.push(activity);
      }

      return updatedActivities;
    }, []);

    return { ...day, day: dayChanges, activities: updatedAct };
  });

  return { ...newItinerary, travelDays: newData };
}

// Hàm để nhóm các ngày hoạt động
export const groupByDateOrder = (entry) => {
  // Định nghĩa tên các ngày và thứ tự tương ứng
  const days = [
    { name: "Thứ 2", active: entry.mon },
    { name: "Thứ 3", active: entry.tue },
    { name: "Thứ 4", active: entry.wed },
    { name: "Thứ 5", active: entry.thu },
    { name: "Thứ 6", active: entry.fri },
    { name: "Thứ 7", active: entry.sat },
    { name: "Chủ Nhật", active: entry.sun },
  ];

  const groups: any = [];
  let currentGroup: any = [];

  // Duyệt qua từng ngày
  for (let i = 0; i < days.length; i++) {
    if (days[i].active) {
      currentGroup.push(days[i].name); // Thêm ngày vào nhóm hiện tại nếu active
    } else if (currentGroup.length > 0) {
      groups.push(currentGroup); // Nếu gặp ngày không active, đóng nhóm hiện tại
      currentGroup = [];
    }
  }

  // Đưa nhóm cuối cùng vào nếu có
  if (currentGroup.length > 0) {
    groups.push(currentGroup);
  }

  // Kết hợp các nhóm để render
  const result = groups
    .map((group) => {
      if (group.length === 1) return group[0]; // Nếu chỉ có 1 ngày trong nhóm
      return `${group[0]} - ${group[group.length - 1]}`; // Nhóm có nhiều ngày liên tiếp
    })
    .join(", ");

  const fromTime = convertNumberToTime(entry.fromTime); // Chuyển đổi fromTime
  const toTime = convertNumberToTime(entry.toTime); // Chuyển đổi toTime
  const lastEntry = convertNumberToTime(entry.lastEntry); // Chuyển đổi lastEntry
  const lastEntryText =
    lastEntry !== "00:00" ? `(Lượt vào cổng cuối ${lastEntry})` : "";

  return `${result}: ${fromTime} - ${toTime} ${lastEntryText}`;
};

export const convertNumberToTime = (time) => {
  const hours = Math.floor(time / 100);
  const minutes = time % 100;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

// Function to format date considering timezone offset
export function formatDateAndTimezone(dateString, offsetInMinutes) {
  const date = new Date(dateString);
  // Adjust for timezone offset in hours
  const utcDate = new Date(date.getTime() + offsetInMinutes * 60 * 1000);
  return utcDate.toISOString().split("T")[0]; // Return YYYY-MM-DD format
}

const cityOptions = MasterData.cities.map((item) => ({
  value: item.name,
  label: item.name,
}));

export const listCountries = [...countriesData().getAll(), ...cityOptions];

export const getCountryName = (data) => {
  return data?.value
    ?.split(",")
    .map((el) => {
      const name = countriesData().getByValue(el)?.label;
      if (name) return I18nCountries.userLanguageName(name);
      else return el;
    })
    .join(",");
};

export const countryNameByCategory = (ecomActivity) => {
  if (!ecomActivity) return;
  const { searchableLocationCountries, coverageArea } = ecomActivity;
  let countriesToUse;
  if (ecomActivity.category.category === CategoryType.CONECTIVITY) {
    countriesToUse = coverageArea.split(",");
  } else if (ecomActivity.category.category === CategoryType.CONVENIENCE) {
    countriesToUse = [
      ...searchableLocationCountries.split(","),
      ...coverageArea.split(","),
    ];
  } else {
    countriesToUse = searchableLocationCountries.split(",");
  }
  const uniqueCountries = Array.from(new Set(countriesToUse)).filter(Boolean);
  if (uniqueCountries.length === 1) {
    return listCountries?.find((item) => item.value === uniqueCountries[0]);
  }
  return "";
};

export const convertAuthorName = (name) => {
  const firstFive = name.slice(0, 4);
  const spaceIndex = name.indexOf(" ");

  if (spaceIndex !== -1) {
    // Tên có khoảng trắng (ví dụ "Anh Nguyen")
    if (name.length > 5) {
      return `${firstFive} *****`;
    }
    return name; // Trả về nguyên tên nếu không dài hơn 5 ký tự
  } else {
    // Tên không có khoảng trắng (ví dụ "AnhNguyen")
    if (name.length > 5) {
      return `${firstFive}${"*".repeat(name.length - 5)}`;
    }
    return name; // Trả về nguyên tên nếu không dài hơn 5 ký tự
  }
};
