"use client";

import { Icons } from "@/components/icons";
import { buttonVariants } from "@/components/ui/button";
import NormalDialog from "@/components/ui/nomal-dialog";
import PopUp from "@/components/ui/popup";
import useLoginModalStore from "@/hooks/auth/use-login-modal-store";
import useIsDesktop from "@/hooks/useIsDesktop";
import { cn } from "@/lib/utils";
import UserAgentUtils from "@/lib/utils/user-agent-utils";
import { signIn } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useQueryState } from "nuqs";
import { useEffect, useState } from "react";
import FooterSignIn from "../../../app/(auth)/login/components/footer-sign-in";
import SignInWithEmail from "../../../app/(auth)/login/components/sign-in-email";
import {
  FeatureFlag,
  featureFlagEnabled,
} from "../../../lib/config/featureFlagEnabled";
import { Logo } from "../../logo/logo";
import LakaSwitchMobileModal from "../LakaSwitchMobileModal";

interface LoginModalProps {}

const LoginModalV2 = (props: LoginModalProps) => {
  const [userAgent, setUserAgent] = useState("");
  const modal = useLoginModalStore();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [showVerificationStep, setShowVerificationStep] = useState(false);
  const [d, setD] = useQueryState("d");
  const [a, setA] = useQueryState("a");
  const [callBackValid, setCallBackValid] = useState("");

  useEffect(() => {
    setUserAgent(window.navigator.userAgent);
    console.log("AA userAgent", window.navigator.userAgent);
  }, []);

  useEffect(() => {
    if (modal.isOpen) {
      console.log("AA login callBackUrl ", modal.callBackUrl);
    }
  }, [modal.isOpen]);

  // useEffect(() => {
  //   if (d && a) {
  //     setIsActive((prev) => !prev);
  //     setCallBackValid(`${modal.callBackUrl}`);
  //   }
  // }, [modal.isOpen]);

  const handleSetActive = () => {
    setIsActive(!isActive);
  };

  const bodyContent = (
    <div className="flex size-full flex-col bg-white pb-8 md:max-w-[428px] md:rounded-3xl">
      {isActive ? (
        <div className="px-6 md:px-0">
          <SignInWithEmail
            handleSetActive={handleSetActive}
            callBackUrl={modal.callBackUrl || "/"}
            setShowVerificationStep={setShowVerificationStep}
            showVerificationStep={showVerificationStep}
          />
        </div>
      ) : (
        <>
          <div className="flex justify-start px-6 pt-4 md:justify-end md:px-0">
            <Icons.close
              className="size-6 cursor-pointer"
              onClick={() => {
                modal.onClose();
              }}
            />
          </div>
          <div className=" flex w-full flex-col justify-center gap-1 ">
            <div>
              <Logo width={[62, 29]} height={22} />
            </div>
            <div className="text-center text-xl font-semibold text-secondary-100">
              Đăng nhập/ đăng ký
            </div>
            <div className="px-6 text-center text-sm font-normal text-[#61677C] md:px-0">
              Để lưu lại thông tin và khám phá ở bất cứ đâu trên Laka.
            </div>
          </div>
        </>
      )}
      {!showVerificationStep && (
        <>
          <div className="flex w-full flex-col items-center gap-3 px-6 pt-4 md:px-0">
            {!isActive && (
              <button
                onClick={() => setIsActive(true)}
                className={`${cn(
                  buttonVariants({ variant: "default", size: "xl" })
                )} w-full !rounded-2xl border  border-line bg-white !text-secondary-100`}
              >
                <Icons.mail stroke="#7A67E4" className="mr-4 size-6" />
                <span> Đăng nhập bằng Email </span>
              </button>
            )}

            {isActive && !UserAgentUtils.isWebView(userAgent) && (
              <>
                <div className="flex w-full items-center gap-4 ">
                  <div className="h-px flex-1 bg-[#EEF0F5]"></div>
                  <div className="text-xs font-normal text-[#61677C]">Hoặc</div>
                  <div className="h-px flex-1 bg-[#EEF0F5]"></div>
                </div>
              </>
            )}

            {!UserAgentUtils.isWebView(userAgent) && (
              <button
                onClick={() => {
                  signIn("google", {
                    callbackUrl: modal.callBackUrl || window.location.href,
                  });
                }}
                className={`${cn(
                  buttonVariants({ variant: "default", size: "xl" })
                )} w-full !rounded-2xl border border-line bg-white  !text-secondary-100 hover:!text-white`}
              >
                <Icons.googleV2 className="mr-4 size-6" />
                <span>Đăng nhập bằng Google</span>
              </button>
            )}
            {featureFlagEnabled(FeatureFlag.FacebookLogin) && (
              <button
                onClick={() => {
                  signIn("facebook", {
                    callbackUrl: modal.callBackUrl || window.location.href,
                  });
                }}
                className={`${cn(
                  buttonVariants({ variant: "active", size: "xl" })
                )} w-full !justify-start px-6`}
              >
                <Icons.facebook className="mr-4 size-6" />
                Tiếp tục bằng Facebook
              </button>
            )}
          </div>
        </>
      )}
      <div className="flex size-full flex-col justify-end">
        <div className=" px-8 pt-8 text-xs font-normal text-[#61677C] md:px-0">
          Bằng cách đăng ký và đăng nhập, bạn đã hiểu và đồng ý với{" "}
          <Link
            href="/policies#dieu-khoan-su-dung"
            target="_blank"
            className="text-action underline"
          >
            Điều Khoản Sử dụng
          </Link>{" "}
          và{" "}
          <Link
            href="/policies#chinh-sach-bao-mat-thong-tin-khach-hang"
            target="_blank"
            className="text-action underline"
          >
            Chính sách bảo mật
          </Link>{" "}
          của Laka.AI
        </div>
      </div>
    </div>
  );

  return (
    <LakaSwitchMobileModal
      hiddenHeader
      maxHeightBody="rounded-t-none"
      minHeightContent="h-full"
      classNames={{
        container: "!z-[101]",
        body: "!px-8 !pb-0 !size-auto",
        content: "!justify-center ",
        overlay: "!z-[101]",
      }}
      isOpen={modal.isOpen}
      body={bodyContent}
      onClose={() => {
        modal.onClose();
      }}
    />
  );
};

export default LoginModalV2;
