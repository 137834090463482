"use client";

import { sendEventGA } from "@/lib/google/google-analytics";
import Image from "next/image";
import React from "react";

const HomeSearchImage = () => {

  return (
    <div
      className="w-full items-center justify-center md:flex"
      onClick={() => sendEventGA("hp_image_header_click")}
    >
      <Image
        src="/images/home/block-right-2x.webp"
        alt="home-search-img"
        className="size-full object-cover md:object-contain"
        width={500}
        height={500}
        quality={100}
        priority
        loading="eager"
      />
    </div>
  );
};

export default React.memo(HomeSearchImage);
