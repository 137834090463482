/* eslint-disable camelcase */

import {
  CancellationPolicyEventType,
  CancellationPolicyType,
  CurrencyData,
  DeliveryType,
  EditStatusEnum,
  ProductType,
  StatusEnum,
  TimeslotType,
  TypePartnerChanges,
  ValidityDateType,
  ValidityInputType,
  ValidityLakaInputDateType,
} from "../enums/ecom-enum";
import { Icons } from "@/components/icons";

export const STATUS_OPTIONS = [
  {
    value: EditStatusEnum.PENDING,
    label: "Pending",
    color: "warning",
  },
  {
    value: EditStatusEnum.COMPLETED,
    label: "Completed",
    color: "success",
  },
];
export const TYPE_OPTIONS = [
  {
    value: TypePartnerChanges.INFO,
    label: "Info",
  },
  {
    value: TypePartnerChanges.PRICE,
    label: "Price",
  },
];
export const ACTION_OPTIONS = [
  {
    value: true,
    label: "Done",
  },
  {
    value: false,
    label: "Not done",
  },
];
export const PUBLISH_OPTIONS = [
  {
    value: StatusEnum.PUBLISHED,
    label: "Published",
  },
  {
    value: StatusEnum.UNPUBLISHED,
    label: "Unpublished",
  },
];

export const ECOM_ACTIVITY_PACKAGE_PRODUCTION_TYPE_OPTIONS = [
  { value: ProductType.REGULAR, label: "Regular" },
  { value: ProductType.COMBO, label: "Combo" },
];

export const ECOM_ACTIVITY_PACKAGE_CURRENCY_OPTIONS = [
  { value: CurrencyData.VND, label: "VND" },
  { value: CurrencyData.THB, label: "THB" },
];

export const VALIDITY_INPUT_TYPE_OPTIONS = [
  { value: ValidityInputType.USER_INPUT, label: "User input" },
  { value: ValidityInputType.LAKA_INPUT, label: "Laka input" },
];

export const VALIDITY_DELIVERY_OPTIONS = [
  { value: DeliveryType.PICK_UP, label: "Pickup" },
  { value: DeliveryType.BY_MAIL, label: "By mail" },
  { value: DeliveryType.AUTO_ACTIVATE, label: "Auto Activate" },
];

export const VALIDITY_DATE_TYPE_OPTIONS = [
  { value: ValidityDateType.PARTICIPATION, label: "Participation" },
  { value: ValidityDateType.ACTIVATION, label: "Activation" },
  { value: ValidityDateType.REDEMPTION, label: "Redemption" },
  { value: ValidityDateType.RECEIVE, label: "Receive" },
  { value: ValidityDateType.OTHER, label: "Other" },
];

export const VALIDITY_LAKA_INPUT_DATE_TYPE_OPTIONS = [
  { value: ValidityLakaInputDateType.PURCHASE, label: "Purchase" },
  { value: ValidityLakaInputDateType.ACTIVATION, label: "Activation" },
  { value: ValidityLakaInputDateType.RECEIVE, label: "Receive" },
  { value: ValidityLakaInputDateType.PICK_UP, label: "Pickup" },
  { value: ValidityLakaInputDateType.OTHER, label: "Other" },
];

// CANCELLATION POLICY OPTIONS

export const ECOM_CANCELLATION_POLICY_TYPE_OPTIONS = [
  { value: CancellationPolicyType.N_A, label: "" },
  { value: CancellationPolicyType.NO, label: "No Cancellation" },
  { value: CancellationPolicyType.FREE, label: "Free Cancellation" },
  {
    value: CancellationPolicyType.CONDITIONAL,
    label: "Conditional cancellation",
  },
];

export const ECOM_CANCELLATION_POLICY_EVENT_TYPE_OPTIONS = [
  {
    value: CancellationPolicyEventType.ACTIVITY_START,
    label: "Activity starts",
  },
  { value: CancellationPolicyEventType.REDEMPTION, label: "Redemption" },
  { value: CancellationPolicyEventType.OTHER, label: "Other" },
];

export const ECOM_ACTIVITY_PACKAGE_OTHER_INFO_REQUIRED_FROM_PARTICIPANT_OPTIONS =
  [
    { value: 0, label: "Not Required" },
    { value: 1, label: "Per Package" },
    { value: 2, label: "Per Participant" },
  ];

export const TIMESLOT_TYPE_OPTIONS = [
  { value: TimeslotType.BY_DAY, label: "By Day" },
  { value: TimeslotType.BY_TIME, label: "By Time" },
];

export const interestLine1Options = [
  {
    label: "Điểm tham quan nổi tiếng ",
    text: "🔥 Điểm tham quan nổi tiếng",
    value: "famous-attraction",
    key: "interestsFamous",
    icon: Icons.interestFamily,
    activeIcon: Icons.interestFamilyActive,
    index: 1,
  },
  {
    label: "Điểm mới lạ",
    key: "interestsHiddenGem",
    value: "hidden-gem",
    text: "💎 Điểm mới lạ",
    icon: Icons.interestHiddenGem,
    activeIcon: Icons.interestHiddenGemActive,
    index: 3,
  },
  {
    label: "Văn hóa lịch sử",
    text: "⛩ Văn hóa lịch sử",
    key: "interestsCultureArt",
    value: "culture-history",
    icon: Icons.interestCulture,
    activeIcon: Icons.interestCultureActive,
    index: 2,
  },
  {
    label: "Thiên nhiên, ngoài trời ",
    text: "🥥 Thiên nhiên, ngoài trời",
    key: "interestsNatureOutdoor",
    value: "nature-outdoor",
    icon: Icons.interestFamily,
    activeIcon: Icons.interestFamilyActive,
    index: 4,
  },
  {
    label: "Thể thao & mạo hiểm",
    text: "🪂 Thể thao, mạo hiểm",
    key: "interestsSportAdventure",
    value: "sport-adventure",
    icon: Icons.interestAdventure,
    activeIcon: Icons.interestAdventureActive,
    index: 6,
  },
  {
    label: "Mua sắm",
    text: "🛍 Mua sắm",
    key: "interestsShopping",
    value: "shopping",
    icon: Icons.interestShopping,
    activeIcon: Icons.interestShoppingActive,
    index: 5,
  },
  {
    label: "Giải trí, hoạt động về đêm",
    text: "🍸 Giải trí, hoạt động về đêm",
    key: "interestsEntertainment",
    value: "entertainment-nightlife",
    icon: Icons.interestFamily,
    activeIcon: Icons.interestFamilyActive,
    index: 11,
  },
  {
    label: "Nghỉ dưỡng, thư giãn",
    text: "🧖‍♀️ Thư giãn, nghỉ dưỡng",
    key: "interestsMedical",
    value: "wellness-travel",
    icon: Icons.interestMedical,
    activeIcon: Icons.interestMedicalActive,
    index: 8,
  },
  {
    label: "Ẩm thực",
    text: "🍜 Ẩm thực",
    key: "interestsCulinary",
    value: "culinary",
    icon: Icons.interestFood,
    activeIcon: Icons.interestFoodActive,
    index: 7,
  },
  {
    label: "Lãng mạn",
    text: "💓 Lãng mạn",
    key: "interestsRomantic",
    value: "romantic",
    icon: Icons.interestRomantic,
    activeIcon: Icons.interestRomanticActive,
    index: 9,
  },
  {
    label: "Gia đình",
    text: "👨‍👩‍👧‍👧 Hoạt động dành cho gia đình",
    key: "interestsFamily",
    value: "family",
    icon: Icons.interestFamily,
    activeIcon: Icons.interestFamilyActive,
    index: 10,
  },
];

export const CURRENCY_OPTIONS = [
  { value: CurrencyData.VND, label: CurrencyData.VND },
  { value: CurrencyData.THB, label: CurrencyData.THB },
  { value: CurrencyData.USD, label: CurrencyData.USD },
];

export const REDEEM_METHOD = [
  { value: "LAKA", label: "Laka voucher + Laka code" },
  { value: "LAKA_MERCHANT", label: "Laka voucher + merchant code" },
  { value: "MERCHANT", label: "Merchant voucher" },
];
export const QR_CODE_PER = [
  { value: "BOOKING", label: "Booking" },
  { value: "UNIT_TYPE", label: "Unit type" },
  { value: "UNIT", label: "Unit" },
];
export const AID_SELECTION_DATE = [
  { value: "PARTICIPATION", label: "Ngày tham gia", labelEl: "Participation" },
  { value: "PURCHASE", label: "Ngày mua", labelEl: "PURCHASE" },
  { value: "RECEIVE/PICK_UP/BY_MAIL", label: "Ngày nhận", labelEl: "Receive" },
  {
    value: "AUTO_ACTIVATE/ACTIVATION",
    label: "Ngày kích hoạt",
    labelEl: "Activation",
  },
  {
    value: "REDEMPTION",
    label: "Ngày sử dụng",
    labelEl: "Redemption",
  },
  {
    value: "WIFI_RENTAL",
    label: "Ngày nhận / ngày trả",
    labelEl: "Pickup Date - Return Date",
  },
];

export const CONFIRMATION_TIME = [
  { value: "INSTANT", label: "Instant" },
  { value: "24H", label: "24h" },
  { value: "48H", label: "48h" },
];
export const TICKET_TYPE = [
  { value: "QRCODE", label: "QR Code" },
  { value: "BARCODE", label: "Bar Code" },
  { value: "PDF", label: "PDF" },
];

export const ALLOWED_IMAGE_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/jpg",
];

export const ALLOWED_VIDEO_FILE_TYPES = ["video/mp4"];
