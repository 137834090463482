"use client";

const BackgroundHome = () => {
  return (
    <>
      <div
        className="absolute -z-10 hidden size-full overflow-x-hidden md:flex"
        style={{
          background:
            "linear-gradient(146deg, #B18AFA 19.72%, #CADFF5 52.31%, #A5D5ED 83.74%)",
        }}
      />

      <div
        className="absolute -z-10 flex size-full overflow-x-hidden md:hidden"
        style={{
          background:
            "linear-gradient(180deg, #BB99F9 0%, #CBB8F9 33.81%, #CAE1F3 84.35%)",
        }}
      />
    </>
  );
};

export default BackgroundHome;
