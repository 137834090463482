"use client";

import { Icons } from "../../../components/icons";
import useIsDesktop from "../../../hooks/useIsDesktop";
import { sendEventGA } from "@/lib/google/google-analytics";
import { useRouter } from "next/navigation";
import React, { useMemo } from "react";

const CommonBanner = ({ country, hideSubText = true, onclick = () => {} }) => {
  const isDeskTop = useIsDesktop();
  const router = useRouter();

  // Memoize srcSet for desktop and mobile to avoid unnecessary re-renders
  const srcSetDeskTop = useMemo(
    () =>
      "/images/iframe/iframe-itinerary.png 1x, /images/iframe/iframe-itinerary-2x.png, /images/iframe/iframe-itinerary-3x.png",
    []
  );
  const srcSetMobile = useMemo(
    () =>
      "/images/iframe/iframe-itinerary-mobile.png 1x, /images/iframe/iframe-itinerary-mobile-2x.png, /images/iframe/iframe-itinerary-mobile-3x.png",
    []
  );

  // Handler for banner click
  const handleClick = () => {
    onclick();
    router.push("/tao-lich-trinh");
  };

  // Render logic for common styles and components
  const desktopBanner = (
    <div className="relative">
      <img
        srcSet={srcSetDeskTop}
        className="size-full rounded-r-2xl object-cover"
        loading="lazy"
        alt="Itinerary Banner"
      />
    </div>
  );

  const mobileBanner = (
    <div className="absolute bottom-0 right-0">
      <img
        srcSet={srcSetMobile}
        className="size-full rounded-r-2xl object-cover"
        loading="lazy"
        alt="Itinerary Banner"
      />
    </div>
  );

  return (
    <div
      className={`${
        !isDeskTop ? "relative flex w-full gap-2" : "flex w-full items-center"
      } cursor-pointer rounded-2xl ${
        hideSubText ? "bg-white" : "bg-[#F5F6FA]"
      }`}
      onClick={handleClick}
    >
      <div
        className={`flex flex-1 flex-col ${hideSubText ? "gap-2" : ""} ${
          !isDeskTop ? "items-start justify-start p-4" : "px-8 py-5"
        }`}
      >
        {isDeskTop && (
          <div className="flex w-fit flex-row flex-nowrap items-center gap-0.5 rounded-full bg-gradient-to-r from-[#D4DBFD] to-[#FFEDFA] px-2 py-1">
            <Icons.AiSmall className="size-4" />
            <span className="h-[14px] text-xs font-semibold tracking-[0.25px] text-[#404867]">
              Hỗ trợ bởi AI
            </span>
          </div>
        )}
        <div
          className={`${
            !isDeskTop ? "text-sm" : "text-[20px] "
          } flex flex-col font-semibold ${hideSubText ? "" : "py-4"}`}
        >
          <span className="text-[#111B42]">
            <span>Tạo lịch trình du lịch </span>
            {country && <span className=""> {country || ""} </span>}
            <span>tự túc</span>
            <span> {hideSubText ? "" : "miễn phí"} </span>
            <span>nhận </span>
            <span className={`${!isDeskTop ? "hidden" : ""} text-[#FF3C62]`}>
              ưu đãi lên đến 40%{" "}
            </span>
            {hideSubText && isDeskTop && (
              <span>khi mua Hoạt động vui chơi</span>
            )}
          </span>
          <span className={`${!isDeskTop ? "" : "hidden"} text-[#FF3C62]`}>
            ưu đãi lên đến 40%
          </span>
          {(!hideSubText || !isDeskTop) && (
            <span className=" md:text-start">khi mua Hoạt động vui chơi</span>
          )}
        </div>
        {hideSubText && (
          <div
            className={`flex gap-2 text-[#61677C] ${
              !isDeskTop ? "flex-row " : "flex-col"
            }`}
          >
            <div className="flex items-center gap-1 text-xs md:text-sm">
              <Icons.check className="size-4" stroke="#24B600" />
              <span className="text-nowrap">Hoàn toàn miễn phí</span>
            </div>
            <div className="flex items-center gap-1 text-xs md:text-sm">
              <Icons.check className="size-4" stroke="#24B600" />
              {!isDeskTop ? (
                <span className="text-nowrap">Chỉ 5 giây, 2 bước</span>
              ) : (
                <span className="text-nowrap">
                  Tối ưu di chuyển, tiết kiệm thời gian
                </span>
              )}
            </div>
          </div>
        )}
        <div className="flex w-fit cursor-pointer items-center justify-center gap-2 rounded-full bg-primary-base px-5 py-1.5 text-sm md:px-6 md:py-3">
          <Icons.AI className="size-5 shrink-0" />
          <span className="text-nowrap font-semibold text-white ">
            Tạo lịch trình ngay
          </span>
        </div>
      </div>
      {!isDeskTop ? mobileBanner : desktopBanner}
    </div>
  );
};

export default React.memo(CommonBanner);
