"use client";

import NextImage from "../ui/next-image";

const features = [
  {
    image: "/images/home/home-itinerary.png",
    title: "Lên lịch trình dễ dàng",
    description:
      "Chỉ vài thao tác đơn giản, bạn sẽ có ngay lịch trình hoàn hảo của mình!",
  },
  {
    image: "/images/home/favorite-location.png",
    title: "Khám phá hàng ngàn điểm đến",
    description: "Gợi ý hàng ngàn điểm đến trending cho chuyến đi đáng nhớ.",
  },
  {
    image: "/images/home/voucher.png",
    title: "Tận hưởng ưu đãi tuyệt vời",
    description: "Hoạt động chất lượng, giá tốt, nhiều khuyến mãi hấp dẫn.",
  },
  {
    image: "/images/home/travel-insurance.png",
    title: "Du lịch an tâm, đáng tin cậy",
    description:
      "Xem các đánh giá chân thực từ khác hàng khác và nhận hỗ trợ khách hàng tận tình từ Laka.",
  },
];

const HomeFeatures = () => {
  return (
    <div className="border-t border-secondary-40">
      <div className="container flex flex-col gap-8 px-4 py-5 md:py-10">
        <h2 className="text-xl font-semibold leading-8 text-secondary-100 md:text-2xl md:leading-[36px]">
          Vì sao bạn nên chọn Laka.AI ?
        </h2>

        <ul className="no-scrollbar flex gap-8 overflow-auto">
          {features.map((item) => (
            <li className="flex min-w-[240px] flex-col gap-2 md:gap-3">
              <div className="flex size-16 items-center justify-center rounded-full bg-[#E9EEFF]">
                <NextImage
                  width={48}
                  height={48}
                  src={item.image}
                  alt="home-features-img"
                />
              </div>
              <h3 className="text-base font-semibold text-black">
                {item.title}
              </h3>
              <p className="text-sm font-normal text-black">
                {item.description}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HomeFeatures;
