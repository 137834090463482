"use client";

import { Icons } from "@/components/icons";
import { Logo } from "@/components/logo/logo";
import { trackClickItiCreate } from "@/lib/metrics/ga/homepage";
import Image from "next/image";
import Link from "next/link";

const Footer = () => {
  return (
    <div
      id="footer-home-page"
      className="flex w-full flex-col justify-start border-t border-secondary-20 md:pt-16"
    >
      <div className="container mt-14 w-full md:mt-0">
        <div className="flex flex-col gap-6 px-4 md:flex-row md:gap-14">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col items-start gap-4">
              <div className="flex h-8 items-start justify-center gap-1.5">
                <Logo width={[92, 44]} height={32} />
                <div className="relative h-8 w-11"></div>
              </div>
              <div className="text-base font-medium text-secondary-100">
                Tạo lịch trình du lịch tự túc miễn phí <br /> Mua hoạt động trải
                nghiệm giá ưu đãi
              </div>
            </div>

            <div>
              <Link
                href={"/tao-lich-trinh"}
                onClick={() => trackClickItiCreate("FOOTER")}
                className="flex h-14 w-[240px] min-w-[240px] items-center justify-center gap-2 rounded-full bg-[#7A67E4] px-6 py-4"
              >
                <Icons.staricon className="size-11" />
                <div className="text-nowrap font-semibold text-white">
                  Tạo lịch trình ngay
                </div>
              </Link>
            </div>
          </div>
          <div className="flex-1">
            <div className="flex flex-row flex-wrap items-start justify-start gap-6 md:flex-nowrap md:justify-center">
              <div className="flex flex-col items-start justify-start gap-2">
                <div className="mb-1 text-lg font-semibold leading-snug text-secondary-100">
                  Về laka
                </div>
                <div className=" text-base font-medium leading-normal text-secondary-80">
                  <a href="/about">Về chúng tôi</a>
                </div>
                <div className=" text-base font-medium leading-normal text-secondary-80">
                  <a href="https://blog.laka.ai/">Laka blog</a>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-2">
                <div className="mb-1 text-lg font-semibold leading-snug text-secondary-100">
                  Sản phẩm
                </div>
                <div className=" text-base font-medium leading-normal text-secondary-80">
                  <a href="/tao-lich-trinh">Tạo kế hoạch du lịch</a>
                </div>
                <div className=" text-base font-medium leading-normal text-secondary-80">
                  <a href="/hoat-dong-dich-vu">Hoạt động & dịch vụ</a>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-2">
                <div className="mb-1 text-lg font-semibold leading-snug text-secondary-100">
                  Khác
                </div>
                <div className=" text-base font-medium leading-normal text-secondary-80">
                  <a href="/terms-of-service#terms-of-service">
                    Điều khoản sử dụng
                  </a>
                </div>
                <div className="text-base font-medium leading-normal text-secondary-80">
                  <a href="/terms-of-service#privacy-policy">
                    Chính sách bảo mật
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <div className="text-lg font-semibold text-secondary-100">
                Theo dõi laka
              </div>
              <div className="flex gap-4">
                <div className="flex h-6 items-start justify-start gap-6">
                  <a
                    href="https://www.facebook.com/lakavietnam"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.facebook className="size-6" aria-label="facebook" />
                  </a>
                  <a
                    href="https://www.youtube.com/@LakaAi-l9t"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.youtube className="size-6" aria-label="youtube" />
                  </a>
                  <a
                    href="https://www.tiktok.com/@lakavietnam"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.tiktok className="size-6" aria-label="tiktok" />
                  </a>
                  <a
                    href="https://www.instagram.com/lakatravel_vietnam/?img_index=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.instagram className="size-6" aria-label="tiktok" />
                  </a>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="text-lg font-semibold text-secondary-100">
                Liên hệ
              </div>
              <div className="mt-6 flex gap-4">
                <div className="flex h-8 items-start justify-start gap-6">
                  <a
                    href="https://zalo.me/3560002558709178527"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.zaloicon className="size-6" aria-label="zaloicon" />
                  </a>
                  <a
                    href="https://www.facebook.com/lakavietnam"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.messengericon
                      className="size-6"
                      aria-label="messengericon"
                    />
                  </a>
                  <a
                    href="mailto:care@laka.ai"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.googleicon
                      className="size-6"
                      aria-label="googleicon"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 bg-gray-900 p-8 py-9 lg:mt-16">
        <div className="container mx-auto flex flex-col gap-4 md:flex-row">
          <a href="http://online.gov.vn/Home/WebDetails/113213" target="_blank">
            <Image
              width={111}
              height={42}
              className="h-[42px] w-[111px] object-contain"
              src="/images/image-MOIT-registered.png"
              alt={"Logo đã thông báo bộ công thương"}
            />
          </a>
          <div className="flex flex-wrap gap-4">
            <div className="flex h-8 w-14 items-center justify-center rounded-md border border-gray-100 bg-white px-px py-2">
              <Icons.visaCard className="relative size-8" />
            </div>
            <div className="flex h-8 w-14 items-center justify-center rounded-md border border-gray-100 bg-white px-px py-2">
              <Icons.masterCard className="relative size-8" />
            </div>
            <div className="flex h-8 w-14 items-center justify-center rounded-md border border-gray-100 bg-white px-px py-2">
              <Icons.jcbCard className="relative size-8" />
            </div>
            <div className="flex h-8 w-14 items-center justify-center rounded-md border border-gray-100 bg-white px-px py-2">
              <Image
                width={32}
                height={32}
                src="https://asset.brandfetch.io/idihpQ2rue/idmK2YRJ_I.svg"
                alt="image-onePay"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
