const requery = async (itineraryId: string, reason: string) => {
  console.log("call api with ", itineraryId, reason);
  const response = await fetch(`/api/itinerary/requery`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ itineraryQueryId: itineraryId, reason: reason }),
  });
  const responseData = await response.json();

  return responseData;
};
const query = async (data) => {
  const response = await fetch("/api/itinerary/query", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...data,
    }),
  });
  const responseData = await response.json();
  return responseData;
};
const deleteQuery = async (queryId: string) => {
  const response = await fetch(`/api/itinerary-v2/${queryId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const responseData = await response.json();
  return responseData;
};

export const ItineraryQueryApiClient = {
  requery,
  query,
  deleteQuery,
};
