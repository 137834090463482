import { MasterData } from "@/lib/data/master-data";
import { Prisma } from "@prisma/client";
import Fuse from "fuse.js";

const cities = MasterData.destinations;
const useSearchLocation = () => {
  function getFilteredCities(limit, inputValue, country) {
    if (!inputValue) return [];

    const options = {
      keys: ["name"], // Tìm kiếm theo trường 'name'
      threshold: 0.3, // Ngưỡng cho fuzzy search
      getFn: (obj, key) =>
        obj[key].normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    };

    const fuse = new Fuse(cities, options);
    const normalizedQuery = inputValue
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const fuseResults = fuse.search(normalizedQuery);

    const filteredResults = fuseResults
      .map((result) => result.item)
      .filter((city) => {
        return (
          country === "" ||
          (country.toLowerCase() === city.country.toLowerCase() &&
            city.type === "city")
        );
      })
      .sort((a, b) => {
        if (a.weight === "high" && b.weight !== "high") return -1;
        if (b.weight === "high" && a.weight !== "high") return 1;
        return 0;
      })
      .slice(0, limit)
      .map((item) => ({
        name: item.name,
        country: item.country,
        type: item.type,
        countryCode: item.countryCode,
        latitude: new Prisma.Decimal(item.latitude ?? 0),
        longitude: new Prisma.Decimal(item.longitude ?? 0),
        isStart: false,
        mode: "",
        durationValue: 1,
      }));
    return filteredResults;
  }
  return {
    getFilteredCities,
  };
};

export default useSearchLocation;
