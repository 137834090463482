"use client";

import { Icons } from "../icons";
import SlideImage from "../slide-img";
import AidItem from "@/app/(main)/home/home-v3/components/AidItem";
import { sendEventGA } from "@/lib/google/google-analytics";
import { trackClickServiceDetail } from "@/lib/metrics/ga/homepage";
import Link from "next/link";
import React from "react";

const ActivityListHeader = ({ href }) => (
  <div className="flex justify-between">
    <div className="text-lg font-semibold md:text-2xl">
      🔥 Hoạt động vui chơi <span className="text-[#FF3C62]">giá siêu hời</span>
    </div>
    <Link
      href={href}
      onClick={() => sendEventGA("hp_ecom_banner_listitem_viewall")}
      className="hidden cursor-pointer items-center text-action md:flex"
    >
      <span className="border-b border-action text-base font-semibold">
        Xem tất cả
      </span>
      <span>
        <Icons.chevronRight className="size-4" />
      </span>
    </Link>
  </div>
);

const MobileViewAllLink = ({ href }) => (
  <div className="flex w-full justify-center md:hidden">
    <Link
      href={href}
      onClick={() => sendEventGA("hp_ecom_banner_listitem_viewall")}
      className="flex cursor-pointer items-center text-action md:hidden"
    >
      <span className="border-b border-action text-base font-semibold">
        Xem tất cả
      </span>
      <span>
        <Icons.chevronRight className="size-4" />
      </span>
    </Link>
  </div>
);

const HomeActivityPublic = ({ ecoms, href }) => {
  if (!ecoms || ecoms.length === 0) return null;

  const handleItemClick = (title) => {
    trackClickServiceDetail(title || "");
    sendEventGA("hp_ecom_banner_listitem_click");
  };

  return (
    <div className="flex w-full flex-col gap-4 md:px-2">
      <ActivityListHeader href={href} />

      <SlideImage
        classNames={{ wrap: " !gap-4 md:!gap-5" }}
        alwaysShowArrowMobile
        alwaysShowArrow
      >
        {ecoms.map((item) => (
          <div key={item.id} onClick={() => handleItemClick(item.title)} className="h-full">
            <AidItem item={item} imagePriority={true} />
          </div>
        ))}
      </SlideImage>

      <MobileViewAllLink href={href} />
    </div>
  );
};

export default HomeActivityPublic;
