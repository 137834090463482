"use client";

import AidItem from "../../app/(main)/home/home-v3/components/AidItem";
import { getEcomActivitiesPublished } from "@/actions/ecom-activity-published";
import { Icons } from "@/components/icons";
import useIsDesktop from "@/hooks/useIsDesktop";
import { sendEventGA } from "@/lib/google/google-analytics";
import { EcomCategory } from "@prisma/client";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState, useCallback } from "react";

const HomeContentAidService = ({ ecomCategory }) => {
  const stickyRef = useRef<HTMLDivElement | null>(null);
  const [isSticky, setIsSticky] = useState(false);
  const [listEcomActivities, setListEcomActivities] = useState<any[]>([]);
  const [activeFilter, setActiveFilter] = useState("ALL");

  const isDeskTop = useIsDesktop();
  const maxAidRender = isDeskTop ? 12 : 10;
  const router = useRouter();

  useEffect(() => {
    sendEventGA("homepage_view", {});
  }, []);

  const fetchActivities = useCallback(() => {
    getEcomActivitiesPublished({
      category: activeFilter === "ALL" ? undefined : activeFilter,
      pageSize: maxAidRender,
    }).then(setListEcomActivities);
  }, [activeFilter, maxAidRender]);

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  useEffect(() => {
    const handleScroll = () => {
      if (stickyRef.current) {
        const { top } = stickyRef.current.getBoundingClientRect();
        setIsSticky(top <= 0);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex w-full justify-center">
      <div className="container relative z-10 my-10 flex w-full max-w-6xl flex-col gap-3 px-0 md:my-14 md:gap-8 md:px-4 ">
        <h2 className="px-4 text-xl font-semibold leading-8 text-secondary-100 md:px-0 md:text-2xl md:leading-9">
          🏖 Khám phá nhiều hoạt động và dịch vụ du lịch
        </h2>

        <div
          ref={stickyRef}
          className={`no-scrollbar sticky top-0 z-50 inline-flex w-full max-w-6xl gap-4 overflow-x-scroll ${
            isSticky ? "bg-slate-50" : ""
          } py-2 pl-4 `}
        >
          <div className="flex w-full flex-1 flex-nowrap justify-start gap-2 ">
            {[
              {
                id: -1,
                name: "Tất cả",
                validityGroup: "",
                category: "ALL",
                createdAt: new Date(),
                updatedAt: new Date(),
                parentId: -1,
              },
              ...ecomCategory,
            ].map((item) => (
              <div
                key={item.id}
                onClick={() => setActiveFilter(item.category)}
                className={`inline-flex flex-none shrink-0 cursor-pointer text-nowrap rounded-full ${
                  activeFilter === item.category
                    ? " border bg-secondary-100 text-white"
                    : " border border-secondary-20 bg-white text-secondary-100"
                } box-border px-4 py-2 text-sm font-medium`}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-x-2 gap-y-2.5 px-2 md:gap-5 md:px-0 lg:grid-cols-4">
          {listEcomActivities.map((item) => (
            <AidItem
              key={item.id}
              onClick={() => {
                router.push(`/activity/${item.slug}`);
                sendEventGA("hp_service_detail_click", { aid: item.title });
              }}
              item={{
                ...item,
                category: {
                  name: item.category.name,
                  category: item.category.category,
                },
              }}
              classNames="!w-full"
            />
          ))}
        </div>
        <div
          onClick={() => {
            sendEventGA("hp_service_viewall_click");
            router.push("/hoat-dong-dich-vu");
          }}
          className="flex w-full items-center justify-center px-2"
        >
          <button className="flex w-full items-center justify-center gap-2 rounded-full border border-secondary-10 bg-white p-4 md:w-fit  ">
            <span className="font-semibold">Xem tất cả hoạt động</span>
            <Icons.chevronRight stroke="#517CFF" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeContentAidService;
